import { Component, OnDestroy, OnInit } from "@angular/core";
import {Menu} from '../../../core/services/menu/menu';
import {TokenStorageService} from '../../../core/services/auth/token-storage.service';
import {RolService} from '../../../core/services/roles/rol.service';
import {UsuarioService} from '../../../core/services/usuario/usuario.service';
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
   idEmpresa: number;
   menus:   Menu[] = [];
  constructor(private tokenStorage: TokenStorageService, private rolService: RolService, private usuarioService: UsuarioService) {

  }


  ngOnInit() {
    this.getMenusByRol();

    const body = document.querySelector("body");

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function(el) {
      el.addEventListener("mouseover", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
    
  }


  public parentId = "";
  
  clickedMenu(event) {
    console.log('metodo clicked menu');
    const target = event.currentTarget;
    const parentId = target.id;
    localStorage.setItem('subMenuSentido', 'emitidas');
    if (parentId === this.parentId) {
      this.parentId = "";
    } else {
      this.parentId = target.id;
    }
  }

  tipo(tipo: string ){
    localStorage.removeItem("tipoNomina");
    localStorage.setItem("tipoNomina",tipo);
    console.log("tipoNominaaa", tipo)
  }


  clickedSubMenu(event){
  
    console.log("event", event.target.id);
    if(event.target.id==="nominaEmpleado"){
      localStorage.setItem("subMenuEmitida", "nominaEmpleado");
    }else if(event.target.id==="nominaEmpresa"){
      localStorage.setItem("subMenuEmitida", "nominaEmpresa");
    } else if(event.target.id==="notaCreditoRecibida"){
      localStorage.setItem('subMenuNotaCreditoRecibida', 'notaCreditoRecibida');
    }
    else if(event.target.id==='notaCreditoEmitida'){
      localStorage.setItem('subMenuNotaCreditoEmitida', 'notaCreditoEmitida');
    }
    else if (event.target.id === 'Emitidas') {
      localStorage.setItem('subMenuSentido', 'emitidas');
    }
    else if (event.target.id === 'Recibidas') {
      localStorage.setItem('subMenuSentido', 'recibidas');
    }
  }
  

  showSubMenu( subMenuCollapse : string): boolean {
    return this.parentId !== subMenuCollapse;
  }

  orderMenus(menus: Menu[]) {
    // Ordenara los menus principales
    this.menus = menus.sort((a, b) => a.id - b.id);

    // Ordenara los sub menus de diagnostico, notas de creditos y pagos.
    this.menus.forEach(menu => {
      if (menu.id <= 4 && menu.id !== 2) {
        menu.subMenus.sort((a, b) => a.nombre.localeCompare(b.nombre));
      }
    });
  }

  getMenusByRol(){
    this.rolService.getMenusByRol(this.tokenStorage.getUser().rol.id).subscribe(
        data => {
          console.log('menus', data);

          this.orderMenus(data);
        }
    );
  }
}
