<div class="row">
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">

          <h2 class="card-title">DETALLE DEL DOCUMENTO
          </h2>
          <button class="btn btn-custom" (click)="goBack()">Regresar</button>
        </div>
        <ngb-tabset class="tab-basic">
          <ngb-tab title="SAT">
            <ng-template ngbTabContent>

              <div class="row">
                <div class="col-md-12">
                  <div class="row flex-grow">
                    <div class="col-12 stretch-card">
                      <div class="card">
                        <div class="card-body">

                          <form [formGroup]="responseDetalleSat" *ngIf="responseDetalleSat">
                            <div class="form-group row">
                              <!-- SERIE -->
                              <div class="col-sm-4">
                                <label for="serie" class="form-label small">SERIE</label>
                                <input type="text" class="form-control form-control-sm w-100" id="serie"
                                  formControlName="serie" disabled>
                              </div>

                              <!-- FOLIO -->
                              <div class="col-sm-4">
                                <label for="folio" class="form-label small">FOLIO</label>
                                <input type="text" class="form-control form-control-sm w-100" id="folio"
                                  formControlName="folio" disabled>
                              </div>

                              <!-- VERSION -->
                              <div class="col-sm-4">
                                <label for="version" class="form-label small">VERSIÓN</label>
                                <input type="text" class="form-control form-control-sm w-100" id="version"
                                  formControlName="version" disabled>
                              </div>

                            </div>

                            <div class="form-group row">

                              <!-- UUID -->
                              <div class="col-sm-8">
                                <label for="uuid" class="form-label small">UUID</label>
                                <input type="text" class="form-control form-control-sm w-100" id="uuid"
                                  formControlName="uuid" disabled>
                              </div>

                              <!-- FECHA TIMBRADO -->
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">FECHA TIMBRADO</label>
                                <input type="text" class="form-control form-control-sm w-100"
                                  formControlName="fechaTimbrado" disabled="true">
                              </div>


                            </div>

                            <div class="form-group row">

                              <!-- FECHA EMISION -->
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">FECHA EMISIÓN</label>
                                <input type="text" class="form-control form-control-sm w-100"
                                  formControlName="fechaEmision" disabled="true">
                              </div>

                              <!-- TIPO COMPROBANTE -->
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">TIPO COMPROBANTE</label>

                                <input type="text" class="form-control form-control-sm w-100"
                                  formControlName="tipoComprobante" disabled="true">

                              </div>

                              <!-- LUGAR DE EXPEDICION -->
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">LUGAR DE
                                  EXPEDICIÓN</label>

                                <input type="text" class="form-control form-control-sm w-100"
                                  formControlName="lugarExpedicion" disabled="true">

                              </div>

                            </div>
                            <div class="form-group row">
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">MONEDA</label>
                                <input type="text" class="form-control form-control-sm w-100" formControlName="moneda"
                                  disabled="true">
                              </div>
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">TIPO DE CAMBIO</label>
                                <input type="text" class="form-control form-control-sm w-100"
                                  formControlName="tipoCambio" disabled="true">
                              </div>
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">EXPORTACIÓN</label>
                                <input type="text" class="form-control form-control-sm w-100"
                                  formControlName="exportacion" disabled="true">
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">FORMA PAGO</label>
                                <input type="text" class="form-control form-control-sm w-100"
                                  formControlName="formaPago" disabled="true">
                              </div>

                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">MÉTODO PAGO</label>
                                <input type="text" class="form-control form-control-sm w-100"
                                  formControlName="metodoPago" disabled="true">
                              </div>
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">SUBTOTAL</label>
                                <input type="text" class="form-control form-control-sm w-100" formControlName="subtotal"
                                  disabled="true">
                              </div>


                            </div>

                            <div class="form-group row">
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">TOTAL</label>
                                <input type="text" class="form-control form-control-sm w-100" formControlName="total"
                                  disabled="true">
                              </div>
                              <div class="col-sm-4">
                                <label for="exampleInputEmail2" class="form-label small">DESCUENTO</label>
                                <input type="text" class="form-control form-control-sm w-100"
                                  formControlName="descuento" disabled="true">
                              </div>

                            </div>

                            <!-- Assume detalleDocumentoVigente is an array and we're interested in the first item -->
                            <div class="form-group row"
                              *ngIf="detalleDocumentoVigente && detalleDocumentoVigente.length > 0 && detalleDocumentoVigente[0].cfdiRelacionados && detalleDocumentoVigente[0].cfdiRelacionados.length > 0">
                              <div class="green-background">
                                <label>CFDI Relacionado</label>
                              </div>
                              <table mat-table [dataSource]="detalleDocumentoVigente[0].cfdiRelacionados"
                                class="mat-elevation-z8" multiTemplateDataRows>
                                <!-- Position Column -->
                                <ng-container matColumnDef="position">
                                  <th mat-header-cell *matHeaderCellDef>No.</th>
                                  <td mat-cell *matCellDef="let element">{{ element.position }}</td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef>Name</th>
                                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                                </ng-container>

                                <!-- Weight Column -->
                                <ng-container matColumnDef="weight">
                                  <th mat-header-cell *matHeaderCellDef>Weight</th>
                                  <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="symbol">
                                  <th mat-header-cell *matHeaderCellDef>Symbol</th>
                                  <td mat-cell *matCellDef="let element">{{ element.symbol }}</td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="['position', 'name', 'weight', 'symbol']"></tr>
                                <tr mat-row *matRowDef="let row; columns: ['position', 'name', 'weight', 'symbol'];">
                                </tr>
                              </table>
                            </div>

                            <div class="form-group">
                              <div class="green-background">
                                <label>Emisor</label>
                              </div>
                              <div class="row"> <!-- Row for RFC and NOMBRE -->
                                <div class="col-lg-6"> <!-- Half width on large screens -->
                                  <label for="rfcEmisor" class="form-label small">RFC</label>
                                  <input type="text" class="form-control form-control-sm w-100" id="rfcEmisor"
                                    formControlName="rfcEmisor" disabled>
                                </div>
                                <div class="col-lg-6"> <!-- Half width on large screens -->
                                  <label for="nombreEmisor" class="form-label small">NOMBRE</label>
                                  <input type="text" class="form-control form-control-sm w-100" id="nombreEmisor"
                                    formControlName="nombreEmisor" disabled>
                                </div>
                              </div>
                              <div class="row"> <!-- Separate Row for REGIMEN FISCAL -->
                                <div class="col-lg-12"> <!-- Full width on large screens -->
                                  <label for="regimenFiscalEmisor" class="form-label small">RÉGIMEN FISCAL</label>
                                  <input type="text" class="form-control form-control-sm w-100" id="regimenFiscalEmisor"
                                    formControlName="regimenFiscalEmisor" disabled>
                                </div>
                              </div>
                            </div>

                            <div class="form-group">
                              <div class="green-background">
                                <label>Receptor</label>
                              </div>
                              <div class="row"> <!-- Row for RFC and NOMBRE -->
                                <div class="col-lg-6"> <!-- Half width on large screens -->
                                  <label for="idReceptor" class="form-label small">RFC</label>
                                  <input type="text" class="form-control form-control-sm w-100" id="idReceptor"
                                    formControlName="idReceptor" disabled>
                                </div>
                                <div class="col-lg-6"> <!-- Half width on large screens -->
                                  <label for="nombreReceptor" class="form-label small">NOMBRE</label>
                                  <input type="text" class="form-control form-control-sm w-100" id="nombreReceptor"
                                    formControlName="nombreReceptor" disabled>
                                </div>
                              </div>
                              <div class="row"> <!-- Separate Row for REGIMEN FISCAL -->
                                <div class="col-lg-6"> <!-- Full width on large screens -->
                                  <label for="domicilioFiscal" class="form-label small">DOMICILIO FISCAL</label>
                                  <input type="text" class="form-control form-control-sm w-100" id="domicilioFiscal"
                                    formControlName="domicilioFiscalReceptor" disabled>
                                </div>
                                <div class="col-lg-6"> <!-- Full width on large screens -->
                                  <label for="regimenFiscalEmisor" class="form-label small">RÉGIMEN FISCAL</label>
                                  <input type="text" class="form-control form-control-sm w-100" id="regimenFiscalEmisor"
                                    formControlName="regimenFiscalReceptor" disabled>
                                </div>
                              </div>
                              <div class="row"> <!-- Separate Row for REGIMEN FISCAL -->
                                <div class="col-lg-4"> <!-- Full width on large screens -->
                                  <label for="residenciaFiscalReceptor" class="form-label small">RESIDENCIA
                                    FISCAL</label>
                                  <input type="text" class="form-control form-control-sm w-100"
                                    id="residenciaFiscalReceptor" formControlName="residenciaFiscalReceptor" disabled>
                                </div>
                                <div class="col-lg-4"> <!-- Full width on large screens -->
                                  <label for="numRegIdTrib" class="form-label small">RÉGIMEN FISCAL</label>
                                  <input type="text" class="form-control form-control-sm w-100" id="numRegIdTrib"
                                    formControlName="numRegIdTrib" disabled>
                                </div>
                                <div class="col-lg-4"> <!-- Full width on large screens -->
                                  <label for="usoCFDIReceptor" class="form-label small">USO CFDI</label>
                                  <input type="text" class="form-control form-control-sm w-100" id="usoCFDIReceptor"
                                    formControlName="usoCFDIReceptor" disabled>
                                </div>
                              </div>
                            </div>


                            <div class="form-group row">
                              <div class="green-background">
                                <label>Conceptos</label>
                              </div>
                              <div class="col-sm-12">
                                <table id="tbl-detalle" class="table table-striped table-bordered table-sm">
                                  <thead style=" background-color: rgb(183, 183, 183)">
                                    <tr class="text-center">
                                      <th scope="col" style="color: white;font-size: .8rem !important;">Clave Prod.
                                        Serv.
                                      </th>
                                      <th scope="col" style="color: white;font-size: .8rem !important;">Clave Unidad
                                      </th>
                                      <th scope="col" style="color: white;font-size: .8rem !important;">Cantidad</th>
                                      <th scope="col" style="color: white;font-size: .8rem !important;">Descripción</th>
                                      <th scope="col" style="color: white;font-size: .8rem !important;">Objeto Imp.</th>
                                      <th scope="col" style="color: white;font-size: .8rem !important;">Valor Unitario
                                      </th>
                                      <th scope="col" style="color: white;font-size: .8rem !important;">Importe</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let item of conceptos">
                                      <td class="text-center" style="font-size: .8rem !important;">{{ item.claveProdServ
                                        || 'N/A' }}</td>
                                      <td class="text-center" style="font-size: .8rem !important;">{{ item.claveUnidad
                                        || 'N/A' }}</td>
                                      <td class="text-center" style="font-size: .8rem !important;">{{ item.cantidad || 0
                                        }}</td>
                                      <td class="text-center" style="font-size: .8rem !important;">{{ item.descripcion
                                        || 'N/A' }}</td>
                                      <td class="text-center" style="font-size: .8rem !important;">{{ item.objetoImp ||
                                        'N/A' }}</td>
                                      <td class="text-center" style="font-size: .8rem !important;">{{ item.valorUnitario
                                        | currency:'MXN' }}</td>
                                      <td class="text-center" style="font-size: .8rem !important;">{{ item.importe |
                                        currency:'MXN' }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                            </div>
                            <div class="form-group row" *ngIf="doctosRelacionados.length > 0">
                              <div class="green-background">
                                <label>Documentos relacionados</label>
                              </div>
                              <div class="container mt-3">
                                <table class="table">
                                  <thead style="background-color: rgb(183, 183, 183)">
                                    <tr>
                                      <th style="color: white; font-size: .8rem !important;">ID Documento</th>
                                      <th style="color: white; font-size: .8rem !important;">Serie</th>
                                      <th style="color: white; font-size: .8rem !important;">Folio</th>
                                      <th style="color: white; font-size: .8rem !important;">Moneda</th>
                                      <th style="color: white; font-size: .8rem !important;">Saldo Anterior</th>
                                      <th style="color: white; font-size: .8rem !important;">Pagado</th>
                                      <th style="color: white; font-size: .8rem !important;">Saldo Insoluto</th>
                                      <th style="color: white; font-size: .8rem !important;">Objeto Impuesto</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let docto of doctosRelacionados" (click)="selectDocto(docto)">
                                      <td style="font-size: 1rem !important;">{{ docto.idDocumento }}</td>
                                      <td style="font-size: 1rem !important;">{{ docto.serie }}</td>
                                      <td style="font-size: 1rem !important;">{{ docto.folio }}</td>
                                      <td style="font-size: 1rem !important;">{{ docto.monedaDR }}</td>
                                      <td style="font-size: 1rem !important;">{{ docto.impSaldoAnt | number }}</td>
                                      <td style="font-size: 1rem !important;">{{ docto.impPagado | number }}</td>
                                      <td style="font-size: 1rem !important;">{{ docto.impSaldoInsoluto | number }}</td>
                                      <td style="font-size: 1rem !important;">{{ docto.objetoImpDR }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <ng-container
                                  *ngIf="selectedDocto && selectedDocto.impuestosDR && selectedDocto.impuestosDR.trasladosDR">
                                  <table class="table table-bordered">
                                    <thead style="background-color: #73b72b">
                                      <tr>
                                        <th style="color: white; font-size: .8rem !important;">Base</th>
                                        <th style="color: white; font-size: .8rem !important;">Impuesto</th>
                                        <th style="color: white; font-size: .8rem !important;">Tipo Factor</th>
                                        <th style="color: white; font-size: .8rem !important;">Tasa/Cuota</th>
                                        <th style="color: white; font-size: .8rem !important;">Importe</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let traslado of selectedDocto.impuestosDR.trasladosDR">
                                        <td style="font-size: .8rem !important;">{{ traslado.baseDR | number }}</td>
                                        <td style="font-size: .8rem !important;">{{ traslado.impuestoDR }}</td>
                                        <td style="font-size: .8rem !important;">{{ traslado.tipoFactorDR }}</td>
                                        <td style="font-size: .8rem !important;">{{ traslado.tasaOCuotaDR | number:
                                          '1.2-2' }}</td>
                                        <td style="font-size: .8rem !important;">{{ traslado.importeDR | number }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </ng-container>
                                <ng-container
                                  *ngIf="!selectedDocto || !selectedDocto.impuestosDR || !selectedDocto.impuestosDR.trasladosDR">
                                  <div class="mt-3 text-center">No hay datos de impuestos disponibles</div>
                                </ng-container>
                              </div>
                            </div>

                            <div class="form-group row" *ngIf="hasData">
                              <div class="green-background">
                                <label>Nómina</label>
                              </div>
                              <div class="container mt-3">
                                <table class="table">
                                  <thead style=" background-color: rgb(183, 183, 183)">
                                    <tr>

                                      <th style="color: white;font-size: .8rem !important;width: 100%;"></th>
                                      <th style="color: white;font-size: .8rem !important;width: 100%;"><strong class="d-block text-center">Detalle</strong></th>
                                      <th style="color: white;font-size: .8rem !important;width: 100%;"></th>
                                      <th style="color: white;font-size: .8rem !important;width: 100%;"></th>
                                      <th style="color: white;font-size: .8rem !important;width: 100%;"></th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>

                                      <td style="font-size: 1rem !important;"
                                        *ngFor="let section of ['Percepciones', 'Deducciones', 'Horas Extras', 'Otros Pagos', 'Incapacidades']; let i = index"
                                        (click)="toggleSection(section)" [class.bg-success]="activeSection === section">
                                        <strong>{{ section }}</strong>
                                        <i class="mdi"
                                          [class]="'mdi ' + (activeSection === section ? 'mdi-chevron-up' : 'mdi-chevron-down')"></i>
                                      </td>

                                    </tr>
                                  </tbody>
                                </table>
                                <div *ngIf="activeSection" class="mt-3">
                                  <table class="table table-bordered">
                                    <thead *ngIf="activeSection === 'Percepciones' || activeSection === 'Deducciones'" style=" background-color: #73b72b">
                                      <tr>
                                        <th style="color: white;font-size: .8rem !important;">Tipo</th>
                                        <th style="color: white;font-size: .8rem !important;">Clave</th>
                                        <th style="color: white;font-size: .8rem !important;">Concepto</th>
                                        <th style="color: white;font-size: .8rem !important;" *ngIf="activeSection === 'Percepciones'">Importe Gravado</th>
                                        <th style="color: white;font-size: .8rem !important;" *ngIf="activeSection === 'Percepciones'">Importe Exento</th>
                                        <th style="color: white;font-size: .8rem !important;" *ngIf="activeSection === 'Deducciones'">Importe</th>

                                      </tr>
                                    </thead>

                                    <thead *ngIf="activeSection === 'Horas Extras'" style=" background-color: #73b72b">
                                        <th style="color: white;font-size: .8rem !important;">Tipo</th>
                                        <th style="color: white;font-size: .8rem !important;">Número de días</th>
                                        <th style="color: white;font-size: .8rem !important;">Horas Extras</th>
                                        <th style="color: white;font-size: .8rem !important;">Importe Pagado</th>
                                    </thead>

                                    <thead *ngIf="activeSection === 'Otros Pagos'" style=" background-color: #73b72b">
                                      <th style="color: white;font-size: .8rem !important;">Tipo</th>
                                      <th style="color: white;font-size: .8rem !important;">Clave</th>
                                      <th style="color: white;font-size: .8rem !important;">Concepto</th>
                                      <th style="color: white;font-size: .8rem !important;">Importe</th>
                                    </thead>

                                    <thead *ngIf="activeSection === 'Incapacidades'" style=" background-color: #73b72b">
                                      <th style="color: white;font-size: .8rem !important;">Tipo</th>
                                      <th style="color: white;font-size: .8rem !important;">Días de Incapacidad</th>
                                      <th style="color: white;font-size: .8rem !important;">Descuento</th>
                                      <th style="color: white;font-size: .8rem !important;">Importe</th>
                                    </thead>

                                    <ng-container *ngIf="activeSection === 'Percepciones' || activeSection === 'Deducciones'">

                                      <tbody *ngFor="let data of nominaDetalleTable">
                                        <tr>
                                          <td style="font-size: .8rem !important;">{{ data.tipoPercepcion ?  data.tipoPercepcion : data.tipoDeduccion }}</td>
                                          <td style="font-size: .8rem !important;">{{ data.clave }}</td>
                                          <td style="font-size: .8rem !important;">{{ data.concepto }}</td>
                                          <td style="font-size: .8rem !important;" *ngIf="activeSection === 'Percepciones'">{{ data.importeGravado | number }}</td>
                                          <td style="font-size: .8rem !important;" *ngIf="activeSection === 'Percepciones'">{{ data.importeExento | number }}</td>
                                          <td style="font-size: .8rem !important;" *ngIf="activeSection === 'Deducciones'">{{ data.importe | number }}</td>

                                        </tr>
                                      </tbody>
                                    </ng-container>

                                    <ng-container *ngIf="activeSection === 'Horas Extras'">
                                        <tbody *ngFor="let horaExtra of horasExtras">
                                            <td style="font-size: .8rem !important;">{{ horaExtra.dias }}</td>
                                            <td style="font-size: .8rem !important;">{{ horaExtra.horasExtra }}</td>
                                            <td style="font-size: .8rem !important;">{{ horaExtra.tipoHoras }}</td>
                                            <td style="font-size: .8rem !important;">{{ horaExtra.importePagado }}</td>
                                        </tbody>
                                    </ng-container>

                                    <ng-container *ngIf="activeSection === 'Otros Pagos'">
                                      <tbody *ngFor="let otroPago of otrosPagos">
                                          <td style="font-size: .8rem !important;">{{ otroPago.tipoOtroPago }}</td>
                                          <td style="font-size: .8rem !important;">{{ otroPago.clave }}</td>
                                          <td style="font-size: .8rem !important;">{{ otroPago.concepto }}</td>
                                          <td style="font-size: .8rem !important;">{{ otroPago.importe }}</td>
                                      </tbody>
                                    </ng-container>

                                    <ng-container *ngIf="activeSection === 'Incapacidades'">
                                          <tbody *ngFor="let incapacidad of listaIncapacidad">
                                          <td style="font-size: .8rem !important;">{{ incapacidad.tipoIncapacidad }}</td>
                                          <td style="font-size: .8rem !important;">{{ incapacidad.diasIncapacidad }}</td>
                                          <td style="font-size: .8rem !important;">{{ incapacidad.descuento }}</td>
                                          <td style="font-size: .8rem !important;">{{ incapacidad.importeMonetario }}</td>
                                          </tbody>
                                    </ng-container>

                                    <ng-container *ngIf="!isEmptyData">
                                      <tbody>
                                        <tr >
                                          <td [colSpan]="colspan" class="text-center">No hay datos para la sección {{
                                            activeSection }}</td>
                                        </tr>
                                      </tbody>
                                    </ng-container>
                                  </table>
                                </div>
                              </div>









                            </div>
                          </form>








                          <!--    </div> -->




                          <!-- <button type="submit" class="btn btn-success mr-2">Submit</button>
                                                        <button class="btn btn-light">Cancel</button>-->

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <!--  <ngb-tab title="{{comparativaCanal}}">
                      <ng-template ngbTabContent>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="row flex-grow">
                              <div class="col-12 stretch-card">
                                <div class="card">
                                  <div class="card-body">
                                    <p class="card-description">
                                      Detalle del documento {{cabeceraCanal}}
                                    </p>
                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-1 col-form-label">FOLIO</label>

                                      <div class="col-sm-5">
                                        <input type="folio" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-1 col-form-label">UUID</label>
                                      <div class="col-sm-5">
                                        <input type="uuid" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>


                                    </div>


                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">Nº EMPLEADO</label>

                                      <div class="col-sm-4">
                                        <input type="folio" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-1 col-form-label">SERIE</label>
                                      <div class="col-sm-5">
                                        <input type="uuid" class="form-control form-control-sm w-100" formControlName="serie" disabled="true">
                                      </div>
                                    </div>

                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">ESTADO</label>

                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">TIPO COMPROBANTE</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">TIPO NOMINA</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                    </div>

                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">FECHA EMISION</label>

                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">FECHA REGISTRO</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">FECHA TIMBRADO</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                    </div>



                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">RFC EMISOR</label>

                                      <div class="col-sm-4">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">RFC EMISOR</label>
                                      <div class="col-sm-4">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">REGIMEN FISCAL E.</label>

                                      <div class="col-sm-4">
                                        <input type="text" class="form-control form-control-sm w-100" formControlName="regimenFiscalEmisor" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">pendiente</label>
                                      <div class="col-sm-4">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                    </div>

                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">ID RECEPTOR</label>

                                      <div class="col-sm-4">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">NOMBRE RECEPTOR</label>
                                      <div class="col-sm-4">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">DOMICILI0 FISCAL R.</label>

                                      <div class="col-sm-4">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">REGIMEN FISCAL R.</label>
                                      <div class="col-sm-4">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                    </div>







                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">FORMA PAGO</label>

                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">METODO PAGO</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">MONEDA</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                    </div>

                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">TOTAL</label>

                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">SUBTOTAL</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" formControlName="subtotal" disabled="true">
                                      </div>
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">DESCUENTO</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" formControlName="descuento" disabled="true">
                                      </div>
                                    </div>


                                    <div class="form-group row">
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">FECHA INICIAL PAGO</label>

                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>

                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">FECHA FINAL PAGO</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                      <label for="exampleInputEmail2" class="col-sm-2 col-form-label">FECHA PAGO</label>
                                      <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm w-100" id="exampleInputEmail2" disabled="true">
                                      </div>
                                    </div>


                                    <div class="form-group row">
                                      <label for="lbDeducciones" class="col-sm-2 col-form-label">T. DEDUCCIONES</label>

                                      <div class="col-sm-10" id="div-scroll-detalle">
                                        <table id="tbl-detalle" class="table table-striped table-bordered table-sm">
                                          <thead class="table-success">
                                            <tr class="text-center">
                                              <th scope="col">ESTADO</th>
                                              <th scope="col">FECHA EMISIÓN</th>
                                              <th scope="col">FECHA REGISTRO</th>
                                              <th scope="col">FECHA TIMBRADO</th>
                                              <th scope="col">FOLIO</th>
                                              <th scope="col">ID RECEPTOR</th>
                                              <th scope="col">NOMBRE EMISOR</th>
                                              <th scope="col">NOMBRE RECEPTOR</th>
                                              <th scope="col">RFC EMISOR</th>
                                              <th scope="col">UUID</th>
                                              <th scope="col">DESCUENTO</th>
                                              <th scope="col">DOMICILIO FISCAL RECEPTOR</th>
                                              <th scope="col">FORMA PAGO</th>
                                              <th scope="col">METODO PAGO</th>
                                              <th scope="col">MONEDA</th>
                                              <th scope="col">REGIMEN FISCAL EMISOR</th>

                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle"> 0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0
                                              </td>

                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                    </div>

                                    <div class="form-group row">
                                      <label for="lbOtrosPagos" class="col-sm-2 col-form-label">T. OTROS PAGOS</label>

                                      <div class="col-sm-10" id="div-scroll-detalle">
                                        <table id="tbl-detalle" class="table table-striped table-bordered table-sm">
                                          <thead class="table-success">
                                            <tr class="text-center">
                                              <th scope="col">ESTADO</th>
                                              <th scope="col">FECHA EMISIÓN</th>
                                              <th scope="col">FECHA REGISTRO</th>
                                              <th scope="col">FECHA TIMBRADO</th>
                                              <th scope="col">FOLIO</th>
                                              <th scope="col">ID RECEPTOR</th>
                                              <th scope="col">NOMBRE EMISOR</th>
                                              <th scope="col">NOMBRE RECEPTOR</th>
                                              <th scope="col">RFC EMISOR</th>
                                              <th scope="col">UUID</th>
                                              <th scope="col">DESCUENTO</th>
                                              <th scope="col">DOMICILIO FISCAL RECEPTOR</th>
                                              <th scope="col">FORMA PAGO</th>
                                              <th scope="col">METODO PAGO</th>
                                              <th scope="col">MONEDA</th>
                                              <th scope="col">REGIMEN FISCAL EMISOR</th>

                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle"> 0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0
                                              </td>

                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                    </div>

                                    <div class="form-group row">
                                      <label for="lbPercepciones" class="col-sm-2 col-form-label">T. PERCEPCIONES</label>

                                      <div class="col-sm-10" id="div-scroll-detalle">
                                        <table id="tbl-detalle" class="table table-striped table-bordered table-sm">
                                          <thead class="table-success">
                                            <tr class="text-center">
                                              <th scope="col">ESTADO</th>
                                              <th scope="col">FECHA EMISIÓN</th>
                                              <th scope="col">FECHA REGISTRO</th>
                                              <th scope="col">FECHA TIMBRADO</th>
                                              <th scope="col">FOLIO</th>
                                              <th scope="col">ID RECEPTOR</th>
                                              <th scope="col">NOMBRE EMISOR</th>
                                              <th scope="col">NOMBRE RECEPTOR</th>
                                              <th scope="col">RFC EMISOR</th>
                                              <th scope="col">UUID</th>
                                              <th scope="col">DESCUENTO</th>
                                              <th scope="col">DOMICILIO FISCAL RECEPTOR</th>
                                              <th scope="col">FORMA PAGO</th>
                                              <th scope="col">METODO PAGO</th>
                                              <th scope="col">MONEDA</th>
                                              <th scope="col">REGIMEN FISCAL EMISOR</th>

                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle"> 0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0</td>
                                              <td class="text-center" ngbTooltip="Visializar Detalle">0
                                              </td>

                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                    </div>















                                    <!--    </div> -->




          <!-- <button type="submit" class="btn btn-success mr-2">Submit</button>
                                        <button class="btn btn-light">Cancel</button>-->

          <!--   </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>


                      </ng-template>
                    </ngb-tab> -->

        </ngb-tabset>
      </div>
    </div>
  </div>

</div>
