import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AccessToken, RequestRefreshToken} from "../login/interface/login.interface";
const AUTH_API = 'http://localhost:8085/bunker/api/v1/auth/';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient) { }

    // login, register

    refreshToken(token: string) : Observable<RequestRefreshToken> {
        return this.http.post<RequestRefreshToken>(AUTH_API + 'refreshtoken', {
            refreshToken: token
        }, httpOptions);
    }
}
