import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {  ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.scss"],
})
export class LineChartComponent implements OnInit {
  @Input() intervalo: number = 0;
  @Input() documento: string= null;
  constructor(private route: Router) {}

  ngOnInit(): void {
    console.log("lineChartData-->", this.lineChartData);
  }
  @Input() labels: Label[] = [
   
  ];
  lineChartType: ChartType = "line";
  @Input() lineChartData: ChartDataSets[] = [
    /* {
      data: [65,34,45],
      label: "Documentos vigentes",
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
      fill: "origin",
    }, */
    /*  {
      data: [28],
      label: "Series B",
      backgroundColor: "rgba(77,83,96,0.2)",
      borderColor: "rgba(77,83,96,1)",
      pointBackgroundColor: "rgba(77,83,96,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(77,83,96,1)",
      fill: "origin",
    },
    {
      data: [180],
      label: "Series C",
      // yAxisID: "y-axis-1",
      backgroundColor: "rgba(255,0,0,0.3)",
      borderColor: "red",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)",
      fill: "origin",
    }, */
  ];

  /*public lineChartOptions: ChartConfiguration["options"] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
     scales: {
      xAxes: [
        {
          display: false,
          ticks: {
            display: false,
            beginAtZero: false,
          },
          gridLines: {
            drawBorder: false,
            color: "#b9c0d3",
            zeroLineColor: "#b9c0d3",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            max: 200,
            min: 0,
            stepSize: 50,
            fontColor: "#878e94",
            beginAtZero: false,
          },
          gridLines: {
            color: "#b9c0d3",
            zeroLineColor: "#b9c0d3",
            display: true,
            drawBorder: false,
          },
        },
      ],
    }, */

  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            stepSize: this.intervalo, 
            fontColor: "#878e94",
          },
          gridLines: {
            color: "#b9c0d3",
            zeroLineColor: "#b9c0d3",
            display: true,
            drawBorder: false
          }
        },
      ],
    },

    /* barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            max: 300,
            min: 0,
            stepSize: 50,
            fontColor: "#878e94",
            beginAtZero: false,
          },
        },
      ],
    } */

    /* scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      "y-axis-0": {
        position: "left",
      },
      "y-axis-1": {
        position: "right",
        grid: {
          color: "rgba(255,0,0,0.3)",
        },
        ticks: {
          color: "red",
        },
      },
    },
 */
    /* plugins: {
      legend: { display: true },
      annotation: {
        annotations: [
          {
            type: "line",
            scaleID: "x",
            value: "March",
            borderColor: "orange",
            borderWidth: 2,
            label: {
              display: true,
              position: "center",
              color: "orange",
              content: "LineAnno",
              font: {
                weight: "bold",
              },
            },
          },
        ],
      },
    }, */
  };


  clickGrafica(event : any): void {
    if (this.documento !== null) {
      console.log("click bar char", this.lineChartData[0].data[0], "2=", this.lineChartData[0].data[1], "3=", this.lineChartData[0].data[2]);
      if (this.lineChartData[0].data[0] > 0 || this.lineChartData[0].data[1] > 0 || this.lineChartData[0].data[2] > 0) {

        this.route.navigate([`home/nomina-${this.documento}/detalles`])
      }
    }

  }
};
