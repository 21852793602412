import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {AsignacionUsuarioModel, UsuarioClass, UsuarioNew} from '../../../modules/auth/interfaces/beans.interfaces';
import { Observable } from 'rxjs';
import {ResponseUpdateUser, Usuario, UsuarioInterfas} from './usuario.interfaces';
import {RegistrarUsuarioResponse} from "../registar-usuario/interfaces/registrar-usuario-response";
import {RegistrarUsuario} from "../registar-usuario/interfaces/registrar-usuario";
//import { UsuarioClass, ClienteModel, RolModel, AsignacionUsuarioModel } from '../feature/auth/interfaces/beans.interface';

@Injectable({
  providedIn: 'root'
})

export class UsuarioService {

  url = `${environment.url}user`;
  url2 = `${environment.url}usuarios`;
  urlAuth = `${environment.url}auth/empresa`;

  constructor(private http: HttpClient) {
    // Quita 'api' y guarda el resultado en url2
   
  }

  listUsuarios(empresa: number): Observable<UsuarioInterfas> {
    return this.http.get<UsuarioInterfas>(`${this.url2}/empresa/${empresa}`);
  }

  agregar(body: UsuarioClass, cliente: String, usuario: String): Observable<UsuarioInterfas> {
    return this.http.post<UsuarioInterfas>(`${this.url}?cliente=${cliente}&usuario=${usuario}`, body);
  }

  count(cliente: String): Observable<number> {
    console.log('Count ?', cliente);
    return this.http.get<number>(`${this.url}?cliente=${cliente}`);
  }

  getPaginado(paginaActual: number, registrosPorPagina: number, cliente: String): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`${this.url}/all?pagina=${paginaActual}&registros=${registrosPorPagina}&cliente=${cliente}`);
  }

  delete(idEliminado: number): Observable<UsuarioInterfas> {
    return this.http.delete<UsuarioInterfas>(`${this.url2}/${idEliminado}`);
  }

  //CIENTES A ASIGNAR

  asignarCliente(body: RegistrarUsuario): Observable<RegistrarUsuarioResponse> {
    return this.http.post<RegistrarUsuarioResponse>(`${this.url}/register/in/aplicativo`, body);
  }
  /*asignarCliente(body: UsuarioNew, generateQR: boolean): Observable<ResponseUserRegister> {
    return this.http.post<ResponseUserRegister>(`${this.url}register?generateQR=${generateQR}`, body);
  }*/

  asignados(idUsuario: string): Observable<UsuarioInterfas> {
    return this.http.get<UsuarioInterfas>(`${this.url}/${idUsuario}/clientes/assigned`);
  }

  porAsignar(idUsuario: string): Observable<UsuarioInterfas> {
    return this.http.get<UsuarioInterfas>(`${this.url}/${idUsuario}/clientes/pending`);
  }

  getByUsername(username: string): Observable<UsuarioInterfas> {
    return this.http.get<UsuarioInterfas>(`${this.url}${username}`);
  }

  getCurrentUser(): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.url2}/current`);
  }

  getUsername(username: string): Observable<UsuarioInterfas> {
    return this.http.get<UsuarioInterfas>(`${this.url}${username}`);
  }
 /* updatePass( usuario: UsuarioClass):Observable<ResponseUser>{//password, username
    //const body=JSON.stringify(usuario);
    return this.http.put<ResponseUser>(`${this.url}/cambio/password`,usuario);
  }*/

  updateUsuario(request: any): Observable<ResponseUpdateUser>{
    console.log("Request", request)
    return this.http.put<ResponseUpdateUser>(`${this.url}/update/by-username/${request.username}`, request);

  }

  addUser(body: UsuarioClass): Observable<UsuarioInterfas> {
    return this.http.post<UsuarioInterfas>(`${this.url2}`, body);
  }

  tokenByEmpresa(idEmpresa: number): Observable<any> {
    return this.http.post<any>(`${this.urlAuth}/${idEmpresa}`,{});
  }

  deshabilitar(idUsuario: number): Observable<any> {
    return this.http.put<any>(`${this.url2}/disable/${idUsuario}`,{});
  }

  habilitar(idUsuario: number): Observable<any> {
    return this.http.put<any>(`${this.url2}/enable/${idUsuario}`,{});
  }

  cambiarPassword(idUsuario: number,body:any): Observable<any> {
    return this.http.put<any>(`${this.url2}/password/${idUsuario}`,body);
  }

  findById(idUsuario: number): Observable<any> {
    return this.http.get<any>(`${this.url2}/${idUsuario}`);
  }

  updateUser(idUsuario: number,body:any): Observable<any> {
    return this.http.put<any>(`${this.url2}/${idUsuario}`,body);
  }

  changePassword(email: string): Observable<any> {
    const endpoint = `${this.url2}/change-password`;
    return this.http.post<any>(endpoint, { email });
  }
}
