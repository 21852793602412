<div class="row">
    <div class="col-12 grid-margin">
        <div class="card">
            <div class="card-body">
                <div  class="row">
                    <div class="col-md-12">
                        <h3 class="mb-3 text-center">{{titulo | uppercase}}</h3>

                        <div  *ngIf="this.documentosPac.length>0" class="row">
                            <div class="col-12 grid-margin">
                                <div class="card">

                                    <div class="card-body" >
                                        <app-concilicacion-doc-not-found [documentos]="documentosPac" (exiteConciliacion)="existDataChild($event)"></app-concilicacion-doc-not-found>

                                    </div>
                                </div>
                            </div>
                        </div>

                       <div *ngIf="this.docsStatus.length>0" class="row">
                            <div class="col-12 grid-margin">
                                <div class="card">

                                    <div class="card-body" >
                                        <app-conciliacion-doc-dif-state [docsStatus]="docsStatus"  (existeConciliacion)="existDataChildStatus($event)"></app-conciliacion-doc-dif-state>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--<div *ngIf="contadorDiferencias()" class="row">
                            <div class="col-12 grid-margin">
                                <div class="card">
                                    <div class="card-body" *ngIf="documentosMontos">
                                        <app-conciliacion-doc-amount [docsDiferenciaMount]="documentosMontos"></app-conciliacion-doc-amount>
                                    </div>
                                </div>
                            </div>
                        </div>-->

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
