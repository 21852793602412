<div class="d-flex align-items-center text-center auth bg-primary">
    <div class="row flex-grow">
        <div class="col-lg-7 mx-auto text-white">
            <div class="row align-items-center d-flex flex-row">
                <div class="col-lg-6 text-lg-right pr-lg-4">
                    <h1 class="display-1 mb-0">404</h1>
                </div>
                <div class="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                    <h2>¡LO SIENTO!</h2>
                    <h3 class="font-weight-light">La página que estás buscando no fue encontrada.</h3>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 text-center mt-xl-2">
                    <img src="assets/undraw_taken_re_yn20.svg" style="height: 200px;" alt="Not Found Image" class="img-fluid not-found-image">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 text-center mt-xl-2">
                    <a class="text-white font-weight-medium" href="/home">Volver al inicio</a>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 mt-xl-2">
                    <p class="text-white font-weight-medium text-center">Copyright &copy; {{ currentYear }} Todos los derechos reservados.</p>
                </div>
            </div>
        </div>
    </div>
</div>
