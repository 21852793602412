import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { Color, Label, MultiDataSet } from 'ng2-charts';

@Component({
  selector: "app-dona",
  templateUrl: "./dona.component.html",
  styleUrls: ["./dona.component.scss"],
})
export class DonaComponent implements OnInit {
  @Input() doughnutChartLabels: Label[] = [
    /*  "Download Sales",
    "In-Store Sales",
    "Mail-Order Sales",  */
  ];

  @Input() doughnutCharData: MultiDataSet = [
    /* [350, 450, 100,3003,240] */
  ];
  doughnutCharType: ChartType = "doughnut";

  public colorDona: Color[] = [
    {
      backgroundColor: [
        "#7C4DE3",
        "#E61961",
        "#74B42C",
        "#DED121",
        "#1AAB95",
        "#00E1FF",
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {
    console.log("data", this.doughnutCharData.length);
  }
}
