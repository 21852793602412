import { Component, Input, OnInit } from '@angular/core';
import {
  Conciliacion,
  Azurian,
} from 'src/app/core/services/nomina/interfaces/nomina-details';
import { ConciliacionService } from 'src/app/core/services/conciliacion/conciliacion.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { ReporteService } from 'src/app/core/services/reporte/reporte.service';
import Swal from 'sweetalert2';
import { NominaDetailsService } from 'src/app/core/services/nomina/nomina-details.service';
import { ToastrService } from 'ngx-toastr';
import { RequestDuplicado } from 'src/app/core/services/nomina/interfaces/nomina.interfaces';
import { RequestDetails } from 'src/app/core/services/nomina/interfaces/beans.interfaces';
import { ClienteObserverService } from 'src/app/core/services/cliente/cliente-observer.service';

@Component({
  selector: 'app-conciliacion-doc-amount',
  templateUrl: './conciliacion-doc-amount.component.html',
  styleUrls: ['./conciliacion-doc-amount.component.scss']
})
export class ConciliacionDocAmountComponent implements OnInit {
  @Input() docsDiferenciaMount: Conciliacion[];
  typeAlert: string;
  messageAlert: string;
  documentosSeleccionados: string[] = [];
  //PAGINADOR
  registrosPorPagina = 10;
  paginaActual = 1;
  totalRegistros = 0;
  totalPaginas = 0;
  requetEmitido: any;
  tipoComprobante: string = null;

  constructor(
      private router: Router,
      private route: ActivatedRoute,

      private conciliacionService: ConciliacionService,
      private toast: ToastrService,
      private reporteService: ReporteService,
      private nominaDetails: NominaDetailsService,
      private clienteObservador: ClienteObserverService,

  ) {
    this.tipoComprobante = this.route.snapshot.paramMap.get("tipoComprobante");

  }

  
  ngOnInit(): void {
    console.log('docsStatus', this.docsDiferenciaMount.length);
    this.clienteObservador.getClientes$().subscribe(dataCliente => {
      this.requetEmitido = new RequestDetails();
      this.requetEmitido.rfc = dataCliente.empresa;
      this.requetEmitido.anio = dataCliente.anio;
      this.requetEmitido.mes = dataCliente.mes.id;
      this.getDocumentosCount(this.requetEmitido,this.tipoComprobante);



    });
  }

  visualizar(uuid: string): void {
    console.log('detalle', uuid);
    localStorage.setItem('uuid', uuid);
    this.router.navigate(['home/nomina-empresa-emitidas/dashboard-empresa/pac/vigente/detalle']);
  }

  conciliacion(documento: Conciliacion) {
    this.eliminarDuplicado(documento.uuid);
  }

  eliminarDuplicado(uuid: string) {
    Swal.fire({
      title: 'Documento ' + uuid,
      text: '¿Estás seguro de cancelar ante el SAT?',
      icon: 'warning',
      confirmButtonColor: ' #73b72b',
      showCancelButton: true,
      confirmButtonText: 'Sí, cancelar ante el SAT',
      cancelButtonText: 'No',
    }).then((resultado) => {
      if (resultado.value) {
        this.nominaDetails.eliminar(uuid).subscribe((data) => {
          if (data['estatus'] === 'Cancelacion exitosa') {
            this.toast.success('Cancelacion correcta');
            console.log('*se elimina la venta*');
          }
        });
      }
    });
  }

  showMessageAlert(tipo: string, mensaje: string) {
    this.messageAlert = mensaje;
    this.typeAlert = tipo;
    setTimeout(() => (this.messageAlert = null), 5000);
  }

  descarga() {
    this.reporteService
        .descargaReporte(this.docsDiferenciaMount)
        .subscribe((data) => {
          console.log('Se va descargar el reporte');
          if (data.httpStatus === 200) {
            const fileBlob = this.base64ToArrayBuffer(data.reporte);
            const archivo = new Blob([fileBlob], { type: 'application/xlsx' });
            console.log(archivo);
            FileSaver.saveAs(archivo, 'Reporte.xlsx');
          } else {
            this.showMessageAlert('info', data.messageDetail);
          }
        });
  }

  base64ToArrayBuffer(base64: any): any {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  selectAll(event: any) {
    console.log('event', event.target.checked);
    if (event.target.checked) {
      console.log(
          'documentos con montos diferentes is true?',
          this.docsDiferenciaMount
      );
      this.docsDiferenciaMount.map((doc) => {
        doc.checked = true;
        this.documentosSeleccionados.push(doc.uuid);
      });
      console.log('documentos emitidos con montos diferentes', this.docsDiferenciaMount);
    } else {
      this.documentosSeleccionados = [];
      this.docsDiferenciaMount.map((doc) => {
        doc.checked = false;
      });
    }
  }

  seleccionado(event: any, uuid: string) {
    if (event.target.checked) {
      this.documentosSeleccionados.push(uuid);
    } else {
      this.documentosSeleccionados = this.documentosSeleccionados.filter(
          (m) => m != uuid
      );
    }
  }

  docsValid() {
    Swal.fire({
      title: 'Documento válidos ',
      text: '¿Seguro que todos los documentos seleccionado, son válidos?',
      icon: 'warning',
      confirmButtonColor: ' #73b72b',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
    }).then((resultado) => {
      if (resultado.value) {
        console.log('REGISTROS SELECCIONADOS');
        console.log(this.documentosSeleccionados);
        this.eliminarValores();
      }
    });
  }

  eliminarValores() {
    for (let index = 0; index < this.documentosSeleccionados.length; index++) {
      this.docsDiferenciaMount.splice(
          this.docsDiferenciaMount.findIndex((item) =>
              item.uuid === this.documentosSeleccionados[index]
          ), 1
      );
    }
    this.statusOK();
  }


  statusOK() {
    Swal.fire({
      title: 'Conciliado con exito',
      icon: 'success',
      confirmButtonColor: ' #73b72b',
      confirmButtonText: 'Salir',
    }).then((resultado) => {
      if (resultado.isConfirmed) {
      }
    });
  }

  contador(): boolean {
    if (
        (this.documentosSeleccionados.length > 0)) {
      return true;
    } else {
      return false;
    }
  }

  getDocumentos(request: RequestDetails, tipoComprobante: string) {
    console.log("request", tipoComprobante);
    this.nominaDetails.getConciliacionMonto(request, 'pac', tipoComprobante, this.paginaActual - 1, this.registrosPorPagina).subscribe(data => {
      this.docsDiferenciaMount = data;
    });
  }

  getDocumentosCount(request: RequestDetails, tipoComprobante: string) {
    console.log("request", tipoComprobante);
    this.nominaDetails.getConciliacionMontoCount(request, 'pac', tipoComprobante).subscribe(data => {
      this.totalRegistros = data['numeroRegistros'];
      this.totalPaginas = 1;
      if(this.totalRegistros != 0){
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPorPagina);
      }

    });
  }

  paginaInicial() {
    this.paginaActual = 1;
    this.getDocumentos(this.requetEmitido, this.tipoComprobante);
  }

  paginaAnterior() {
    if (this.paginaActual > 1) {
      this.paginaActual--;
      this.getDocumentos(this.requetEmitido, this.tipoComprobante);
    }
  }

  paginaSiguiente() {
    if (this.paginaActual < this.totalPaginas) {
      this.paginaActual++;
      this.getDocumentos(this.requetEmitido, this.tipoComprobante);
    }
  }

  paginaFinal() {
    this.paginaActual = this.totalPaginas;
    this.getDocumentos(this.requetEmitido, this.tipoComprobante);
  }

  onPageSizeChange(size: number) {
    this.registrosPorPagina = size;
    this.paginaActual = 1;
    this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPorPagina);
    this.getDocumentos(this.requetEmitido, this.tipoComprobante);
  }

}
