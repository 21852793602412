<div class="row" >
  <div class="col-md-12 col-sm-12 grid-margin">
    <div class="card card-statistics">
      <div class="card-body">
         <canvas baseChart
        [data]="doughnutCharData"
        [labels]="doughnutChartLabels"
        [colors]="colorDona"
        [chartType]="doughnutCharType">
      </canvas>
      </div>
    </div>
  </div>

