
export interface ResponseDetalleDashboard {
  sat: Azurian[];
}

export interface Azurian {
  estado: string;
  fechaEmision: string;
  fechaRegistro: string;
  fechaTimbrado: string;
  folio: string;
  idReceptor: string;
  nombreEmisor: string;
  nombreReceptor: string;
  rfcEmisor: string;
  uuid: string;
  descuento: number;
  domicilioFiscalReceptor: string;
  formaPago: string;
  metodoPago: string;
  moneda: string;
  regimenFiscalEmisor: string;
  regimenFiscalReceptor: string;
  serie: string;
  subtotal: number;
  tipoCambio: number;
  tipoComprobante: string;
  total: number;
  totalImpuestosRetenidos: number;
  totalImpuestosTrasladados: number;
  usoCfdiReceptor: string;
  antiguedadReceptor: string;
  fechaInicialPago: string;
  fechaFinalPago: string;
  fechaPago: string;
  numDiasPagados: number;
  numEmpleadoReceptor: string;
  tipo: string;
  totalDeducciones: number;
  totalOtrosPagos: number;
  totalPercepciones: number;
  checked?: boolean;
  hash:string;
}


export class RequestCancelacion{
  uuid: string;
  motivo:string ;
  folioSustitucion:string;
}


export class ResponseDuplicado{
  estatus: string;
}

export class RequestCancelacionMasiva{
  cancelaciones: Cancelacion[];
}
export class Cancelacion{
  uuid: string;
  motivo:string ;
  folioSustitucion:string;
}

export interface Conciliacion {
  id:             number;
  uuid:           string;
  folio:          string;
  version:        string;
  fechaTimbrado:  string;
  fechaEmision:   string;
  fechaRegistro:  string;
  rfcEmisor:      string;
  nombreEmisor:   string;
  idReceptor:     string;
  nombreReceptor: string;
  estado:         string;
  tipo:           string;
  canal:          string;
  comprobante:    Comprobante;
  checked?: boolean;
}

export interface Comprobante {
  serie:                         string;
  formaPago:                     null;
  condicionesPago:               null;
  subtotal:                      number;
  descuento:                     number;
  motivoDescuento:               null;
  moneda:                        string;
  tipoCambio:                    null;
  total:                         number;
  tipoComprobante:               string;
  metodoPago:                    string;
  lugarExpedicion:               string;
  exportacion:                   null;
  numCuentaPago:                 null;
  confirmacion:                  null;
  periodicidad:                  null;
  meses:                         null;
  anio:                          null;
  regimenFiscalEmisor:           string;
  facAtrAdquiriente:             null;
  domicilioFiscalReceptor:       string;
  residenciaFiscalReceptor:      null;
  numRegistroIdTrib:             null;
  regimenFiscalReceptor:         string;
  usoCfdiReceptor:               string;
  rfcCuentaTerceros:             null;
  nombreCuentaTerceros:          null;
  regimenFiscalCuentaTerceros:   null;
  domicilioFiscalCuentaTerceros: null;
  totalImpuestosTraslados:       null;
  totalImpuestosRetenidos:       null;
  cfdisRelacionados:             any[];
  conceptos:                     Concepto[];
  impuestos:                     any[];
  nomina11:                      null;
  nominas12:                     Nominas12[];
  pagos:                         any[];
}

export interface Concepto {
  numero:            number;
  cantidad:          number;
  unidad:            null;
  numIdentificacion: null;
  descripcion:       string;
  valorUnitario:     number;
  importe:           number;
  claveProdServ:     string;
  claveUnidad:       string;
  descuento:         number;
  objetoImp:         string;
  cuentaPredial:     null;
  numeroPedimento:   null;
  impuestos:         any[];
}

export interface Nominas12 {
  numero:                         number;
  version:                        string;
  tipo:                           string;
  fechaPago:                      string;
  fechaInicialPago:               string;
  fechaFinalPago:                 string;
  numDiasPagados:                 number;
  totalPercepciones:              number;
  totalDeducciones:               number;
  totalOtrosPagos:                number;
  registroPatronal:               string;
  totalSueldos:                   number;
  totalGravado:                   number;
  totalExento:                    number;
  totalOtrasDeducciones:          number;
  totalImpuestosRetenidosDeduc?:  number;
  curpReceptor:                   string;
  numSeguridadSocialReceptor:     string;
  fechaIniRelaLaboralReceptor:    string;
  antiguedadReceptor:             string;
  tipoContratoReceptor:           string;
  tipoJornadaReceptor:            string;
  tipoRegimenReceptor:            string;
  numEmpleadoReceptor:            string;
  riesgoPuestoReceptor:           string;
  periodicidadPagoReceptor:       string;
  salarioBaseCotAporReceptor:     number;
  salarioDiarioIntegradoReceptor: number;
  claveEntFedReceptor:            string;
  subcontrataciones:              any[];
  percepciones:                   Percepcione[];
  deducciones:                    Deduccione[];
  otrosPagos:                     Deduccione[];
  incapacidades:                  any[];
}

export interface Deduccione {
  numero:           number;
  tipo:             string;
  clave:            string;
  concepto:         string;
  importe:          number;
  subsidioCausado?: number;
}

export interface Percepcione {
  numero:         number;
  tipo:           string;
  clave:          string;
  concepto:       string;
  importeExento:  number;
  importeGravado: number;
  horasExtras:    any[];
}

export interface ResponseCount {

  nombreOperacion: string;
  numeroRegistros: number ;
}

