import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClienteService } from "src/app/core/services/cliente/cliente.service";
import { Cliente, ClienteModel } from "src/app/modules/auth/interfaces/beans.interfaces";
import { LoginService } from "src/app/core/services/login/login.service";
import { Catalogo } from "src/app/core/services/util.intefaces";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ClienteObserverService } from "src/app/core/services/cliente/cliente-observer.service";
import {DataCliente, Empresa} from "src/app/core/services/cliente/cliente.interface";
import { EmpleadoObserverService } from "src/app/core/services/empleado/empleado-observer.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Empleado } from "src/app/core/services/nomina/interfaces/nomina-empleado";
import { debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { EmpleadoService } from "src/app/core/services/empleado/empleado.service";
import { TokenStorageService } from "src/app/core/services/auth/token-storage.service";
import { UsuarioService } from "src/app/core/services/usuario/usuario.service";
import { SearchStorageService } from "src/app/core/services/search/search-storage.service";


@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [NgbDropdownConfig],
})
export class NavbarComponent implements OnInit, OnDestroy {
  modal: any;
  clients: ClienteModel[] = [];
  clie: Cliente;

  username = "";
  correo = "";

  titulo = "";
  sociedadLista: string[] = [];
  @Input() empresas: Empresa[]=[];

  empresaSeleccion = "";
  cliente = "";
  fechaInicio: Date;
  fechaFinal: Date;
  meses: Catalogo[] = [];
  anios: number[] = [];
  mes: Catalogo = null;
  anio: number = null;
  formCabecera= this.formGroup.group({
    cliente: [this.cliente],
    mes: [this.meses[10]],
    anio: [this.anios[0]],
    empresa: [],
    rfcEmpleado: [],
  });
  clienteDataForm: DataCliente;
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  searchEmpleado$ = new BehaviorSubject<string>("");
  empleadosMatchs: Empleado[] = [];
  employeesFiltered: Observable<Empleado[]>;
  nombre:string=null;


  private OnDestroy$ = new Subject();
  toggleRightSidebar() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  toggleIconOnlySidebar() {
    this.iconOnlyToggled = !this.iconOnlyToggled;
    if (this.iconOnlyToggled) {
      document.querySelector("body").classList.add("sidebar-icon-only");
    } else {
      document.querySelector("body").classList.remove("sidebar-icon-only");
    }
  }

  constructor(
    config: NgbDropdownConfig,
    private router: Router,
    private modalService: NgbModal,
    private clienteService: ClienteService,
    private spinner: NgxSpinnerService,
    private login: LoginService,
    private formGroup: FormBuilder,
    private clienteObv: ClienteObserverService,
    private empleadoObv: EmpleadoObserverService,
    private route: ActivatedRoute,
    private empleadoService: EmpleadoService,
    private storageService: TokenStorageService,
    private usuarioService: UsuarioService,
    private searchStorageService: SearchStorageService,
    private clienObserver:ClienteObserverService,
    private tokenService: TokenStorageService
  ) {
    console.log(" tokeen ");
    this.tokenEmpresa();
    config.placement = "bottom-right";
    
   // this.username = localStorage.getItem("nombre");
   // this.correo = localStorage.getItem("idUsuario");
  }
  ngOnDestroy(): void {
    console.log("observador empleado destroys");

  }


  ngOnInit() {
    console.log("rfcCargado", localStorage.getItem('rfc'));
    console.log("this.buildFormCabecera()",this.buildFormCabecera);

  this.formCabecera.get('empresa').setValue(this.empresas[this.getEmpresaCargada()]);
  console.log("this.formCabecera.get('empresa').value",this.formCabecera.get('empresa').value);
  console.log('ID EMPRESA AL RECARGAR DIAGNOSTICO',localStorage.getItem("idEmpresa"));
  //this.tokenEmpresa();
  }

  empresaClick() {
    console.log("cambio de empresa " + this.empresaSeleccion);
    this.busqueda();
  }

  /*mesSeleccion() {
    console.log("Mes Seleccionado");
  }*/

  /*nio1Click(dato: any) {
    this.fechaInicio = dato;
    console.log("cambio de anio " + this.fechaInicio.toString());
    this.busqueda();
  }

  anio2Click(dato: any) {
    this.fechaFinal = dato;
    console.log("cambio de anio final " + this.fechaFinal.toString());
    this.busqueda();
  }*/

  busqueda() {
    //console.log("EMPRESA" + this.empresaSeleccion);
    //console.log("INICIO" + this.fechaInicio);
   //console.log("TERMINO" + this.fechaFinal);

    //localStorage.setItem("fechaInicio", this.fechaInicio.toString());
    //localStorage.setItem("fechaFinal", this.fechaFinal.toString());
    //localStorage.setItem("empresa", this.empresaSeleccion);

    //console.log("INICIO" + localStorage.getItem("fechaInicio"));
    //console.log("TERMINO" + localStorage.getItem("fechaFinal"));

    //window.location.reload();
  }

  salir() {
    console.log("Login ?");
    this.login.cerrarSession();
    this.router.navigate(['/auth/login']);
  }

  goModificarContrasena() {
    //this.router.navigate(['administracion/modificarContrasena/primerLogin']);
    this.router.navigate(["administracion/modificarContrasena/nuevaPass"]);
  }

  goModificarCuenta() {
    this.router.navigate(["administracion/primerLogin"]);
  }

  clientes(content: any) {
    this.clients = [];
    this.clie = new Cliente();
    this.clie.id = localStorage.getItem("cliente");
    //this.spinner.show();
    this.clienteService.getListadoCompleto().subscribe((data) => {
      //if (data.httpStatus === 200) {
        this.clients = data;
        this.modalService.open(content, {
          ariaLabelledBy: "modal-basic-title",
        });
      //}

    });
  }

  close() {
    this.modalService.dismissAll();
  }

  cambioCliente() {
    //console.log( this.clie.id);
    //this.spinner.show();
    localStorage.setItem("cliente", this.clie.id);
    window.location.reload();
  }

 /* cargaMeses() {
    this.meses = [
      { id: 1, nombre: "Enero" },
      { id: 2, nombre: "Febrero" },
      { id: 3, nombre: "Marzo" },
      { id: 4, nombre: "Abril" },
      { id: 5, nombre: "Mayo" },
      { id: 6, nombre: "Junio" },
      { id: 7, nombre: "Julio" },
      { id: 8, nombre: "Agosto" },
      { id: 9, nombre: "Septiembre" },
      { id: 10, nombre: "Octubre" },
      { id: 11, nombre: "Noviembre" },
      { id: 12, nombre: "Diciembre" },
    ];
  }*/
  /*cargaAnio() {
    this.anios = [2022, 2021, 2020, 2019, 2018];
  }*/


  cambiar() {

    console.log("Change empresa", this.clienteDataForm);
    this.clienteDataForm = this.formCabecera.value;

    let tipo = localStorage.getItem("subMenuEmitida");
    switch(tipo){
      case 'nominaEmpleado': 
      this.empleadoObv.clienteOb(this.clienteDataForm);
      break;
      case 'nominaEmpresa' || 'notaCreditoEmitida' || 'notaCreditoRecibida':
        console.log( "cambio de rfc",this.clienteDataForm );
    }
    
    const empresaData: any = this.clienteDataForm.empresa;
    console.log( "empresa seleccionada", empresaData);
    if (empresaData) {
        localStorage.setItem("rfc", empresaData.rfc);
        localStorage.setItem("idEmpresa", String(empresaData.id));
        this.tokenEmpresa();
    } else {
        // Handle the case where empresaData is null
        console.log("empresaData is null");
    }
    console.log(" getBusquedaMensual "+JSON.stringify(this.searchStorageService.getBusquedaMensual()));
    this.clienObserver.clienteOb(this.searchStorageService.getBusquedaMensual());


  }

  tokenEmpresa() {
    const empresa = Number(localStorage.getItem("idEmpresa"));
    console.log(" empresa "+empresa);
    this.usuarioService.tokenByEmpresa(empresa).subscribe(
      response => {
        console.log("Respuesta recibida:", response);

        // Extrayendo el token del objeto de respuesta
        const token = response.token;

        console.log("Token recibido:", token);
        localStorage.setItem("tokenByEmpresa", token);  // Almacenando el token en localStorage
        this.tokenService.saveToken(token);

      },
      error => {
        console.error("Error al obtener el token:", error);
        // Manejo del error aquí
      }
    );
}

  private buildFormCabecera(): void {
    this.formCabecera = this.formGroup.group({
      cliente: [this.cliente],
      mes: [this.meses[10]],
      anio: [this.anios[0]],
      empresa: [this.empresas[this.getEmpresaCargada()].rfc],
      rfcEmpleado: [],
    });
  }

  isIgual() {
    let tipo = localStorage.getItem("subMenuEmitida");
    if (tipo === 'nominaEmpleado') {
      localStorage.setItem("rfcEmpleado", this.formCabecera.get("rfcEmpleado").value);
      return true;
    }
    return false;
  }

  getEmpleado(value: string) {
    if (value.trim().length > 0 && value !== "") {
      this.searchEmpleado$
        .pipe(
          debounceTime(1000), 
          /*distinctUntilChanged(), */
          takeUntil(this.OnDestroy$),
        ).subscribe(data => {
          this.empleadoService.getEmpleado(value).subscribe(data => {
            this.empleadosMatchs = data;
           })

        })
    } 
  }

  getEmpresaCargada() : number{
    console.log("getEmpresaCargada", localStorage.getItem('rfc'));
    const rfcCargada= localStorage.getItem('rfc');
    console.log('index empresa seleccionada',  this.empresas.findIndex(empresa => empresa.rfc===rfcCargada))
    return  this.empresas.findIndex(empresa => empresa.rfc===rfcCargada) ;
  }

}
