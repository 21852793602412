import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse, HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { TokenStorageService } from '../services/auth/token-storage.service';
import { AuthService } from '../services/auth/auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from 'ngx-spinner';

// const TOKEN_HEADER_KEY = 'Authorization';  // for Spring Boot back-end
const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    constructor(
        private spinner: NgxSpinnerService,
        private tokenService: TokenStorageService,
        private authService: AuthService,
        private route: Router,
        private toast: ToastrService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req;
        const token = this.tokenService.getToken();

        if (token) {
            authReq = this.addTokenHeader(req, 'Bearer ' + token);
        }

        this.spinner.show();
        return next.handle(authReq).pipe(
            finalize(() => this.spinner.hide()),
            catchError(error => {
                if (!authReq.url.includes('auth/authenticate') && error.status === HttpStatusCode.Forbidden) {
                    return this.handle403Error(authReq, next);
                } else if (error.status === HttpStatusCode.Unauthorized) {
                    this.tokenService.clearStorage();
                    this.route.navigate(['auth/login']).then(r => true);
                }
                return throwError(error);
            })
        );
    }

    private handle403Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const refreshToken = this.tokenService.getRefreshToken();

            if (refreshToken) {
                return this.authService.refreshToken(refreshToken).pipe(
                    switchMap((token: any) => {
                        this.isRefreshing = false;
                        this.tokenService.saveToken(token.accessToken);
                        this.tokenService.saveRefreshToken(token.refreshToken);
                        this.refreshTokenSubject.next(token.accessToken);
                        return next.handle(this.addTokenHeader(request, token.accessToken));
                    }),
                    catchError((err) => {
                        this.isRefreshing = false;
                        this.tokenService.clearStorage();
                        this.route.navigate(['auth/login']).then(r => true);
                        return throwError(err);
                    })
                );
            }
        }
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token!)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
    }
}

export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
