import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/core/services/cliente/cliente.service';
import {Empresa} from "../../../core/services/cliente/cliente.interface";
import {TokenStorageService} from "../../../core/services/auth/token-storage.service";
@Component({
  selector: 'app-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.scss']
})
export class LayoutAdminComponent implements OnInit {
  title = "Azurian Bunker";
  empresas: Empresa[]=[];
  constructor(private clienteService: ClienteService, private storageService: TokenStorageService) { }

  ngOnInit(): void {

    this.empresas=this.storageService.getUser().assginedEmpresas;
  }


}
