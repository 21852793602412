<router-outlet>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#52DD3C" type="timer" [fullScreen]="true">
        <p style="color: white"> Cargando... ¡Espere por favor!... </p>
    </ngx-spinner>
</router-outlet>
<!--<div class="container-scroller">
  <app-navbar></app-navbar>
  <div class="container-fluid page-body-wrapper">
    <app-sidebar></app-sidebar>
    <div class="main-panel">
      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>-->
