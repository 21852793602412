import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RequestDetails } from './interfaces/beans.interfaces';
import {
  Azurian,
  Cancelacion,
  Conciliacion,
  RequestCancelacion,
  RequestCancelacionMasiva,
   ResponseCount,
  ResponseDetalleDashboard,
  ResponseDuplicado
} from './interfaces/nomina-details';
import { RequestDuplicado } from './interfaces/nomina.interfaces';


@Injectable({
  providedIn: 'root'
})
export class NominaDetailsService {
  //RequestDetails

  url = `${environment.url}details/`;
  urlDuplicado = `${environment.url}documentos`;
  urlCancelacion = `${environment.url}/cancelacion`;
 // urlDetalleConciliacion = `${environment.url}api/details/inexistentes/pac/`;
  constructor(private http: HttpClient) {

  }

  getDocumentos(request: RequestDetails, page: number, size: number, canal:  string): Observable<Azurian[]> {
    console.log("Request duplicados", request);

    console.log('page', page);
    console.log('size', size);
    return this.http.get<Azurian[]>(`${this.url}${canal}/${request.mes}/${request.anio}/duplicados/${page}/${size}`).pipe(
      map(docs => docs.map(item => {
        return {
          ...item,
          checked: false
        };
      })
    ));
  }



  getConciliacionDocumentos(request: RequestDetails, canal: string, tipoComprobante: string, page: number , size: number, sentido: string  ) {
    const url = `${this.url}inexistentes/${canal}/tipo/${tipoComprobante}/${sentido}/${request.mes}/${request.anio}/${page}/${size}`;
    console.log('La url es:  ', url);
    return this.http.get<Conciliacion[]>(url).pipe(
        catchError(error => {
          console.log('Error conciliación documentos: ', error);
          return of({ error: true, message: 'Error conciliación en documentos' });
        })
    );
  }

  getActualizacionStatus(request: RequestDetails, canal: string , tipoComprobante: string, page: number, size: number, sentido: string) {
    const url = `${this.url}status/${canal}/tipo/${tipoComprobante}/${sentido}/${request.mes}/${request.anio}/${page}/${size}`;
    console.log('La url es:  ', url);
    return this.http.get<Conciliacion[]>(url).pipe(
        catchError(error => {
          console.log('Error en actualizacion estatus: ', error);
          return of({ error: true, message: 'Error en actualizacion estatus' });
        })
    );
  }
  
  getConciliacionMonto(request: RequestDetails, canal: string , tipoComprobante : string,page:number=1,size:number=10){
    let body = JSON.stringify(request);
    return this.http.get<Conciliacion[]>(`${this.url}montos/${canal}/tipo/${tipoComprobante}/${request.mes}/${request.anio}/${page}/${size}`);

  }
  
  eliminar(uuid: string) :  Observable<RequestCancelacion> {
    const reqCancelacion = new RequestCancelacion();
    reqCancelacion.uuid = "00ec8a00-fe0a-429c-9588-a0d8ebccdc49";
    reqCancelacion.motivo = "01";
    let body = JSON.stringify(reqCancelacion);

   
    return this.http.post<RequestCancelacion>(`${this.urlCancelacion}cancelacion`, body);
  }


  duplicadoValido(request : RequestDuplicado): Observable<RequestCancelacion> {
    return this.http.put<RequestCancelacion>(`${this.urlDuplicado}/duplicados/aceptar/uuids`, request);
  }

  cancelacionMasiva(request: Cancelacion[]) :  Observable<RequestCancelacion> {
    return this.http.post<RequestCancelacion>(`${this.urlCancelacion}/masivo`, request);
  }

  getActualizacionStatusCount(request: RequestDetails, canal: string , tipoComprobante: string, sentido: string) {
    const url = `${this.url}status-count/${canal}/tipo/${tipoComprobante}/${sentido}/${request.mes}/${request.anio}`;
    console.log('La url es:  ', url);
    return this.http.get<ResponseCount>(url).pipe(
        catchError(error => {
          console.log('Error en actualizacion estatus cantidad: ', error);
          return of({ error: true, message: 'Error en actualizacion estatus' });
        })
    );
  }

  getConciliacionDocumentosCount(request: RequestDetails, canal: string, tipoComprobante: string, sentido : string) {
    const url = `${this.url}inexistentes-count/${canal}/tipo/${tipoComprobante}/${sentido}/${request.mes}/${request.anio}`;
    console.log('La url es:  ', url);
    return this.http.get<ResponseCount>(url).pipe(
        catchError(error => {
          console.log('Error conciliación documentos cantidad: ', error);
          return of({ error: true, message: 'Error conciliación en documentos' });
        })
    );
  }

  getConciliacionMontoCount(request: RequestDetails, canal: string , tipoComprobante : string){
    let body = JSON.stringify(request);
    return this.http.get<Conciliacion[]>(`${this.url}montos-count/${canal}/tipo/${tipoComprobante}/${request.mes}/${request.anio}`);

  }


  getDuplicadosCount(idEmpresa: number, mes: number, anio: number, canal: string) {
  console.log('nominaDuplicados()', idEmpresa, mes, anio);
  const urlNom = `${this.url}duplicados-count/${canal}/${mes}/${anio}`;
  return this.http.get<ResponseCount>(urlNom);
  }


}
