import { Component, Input, OnInit } from '@angular/core';
import {ChartOptions, ChartType} from 'chart.js';
import { Label,  SingleDataSet } from 'ng2-charts';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
@Component({
  selector: "app-pie-efos",
  templateUrl: "./pie-efos.component.html",
  styleUrls: ["./pie-efos.component.scss"],
})
export class PieEfosComponent implements OnInit {
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: '#fff', 
        font: {
          size: 7, 
        },
        formatter: (value, ctx) => {
          const dataArr = ctx.chart.data.datasets[0].data as number[];
          const sum = dataArr.reduce((acc, cur) => acc + cur, 0);
          const percentage = ((value as number) / sum * 100).toFixed(2) + '%';
          return percentage;
        }
      }
    }
  };
  public pieChartPlugins = [ChartDataLabels]; // Añade esta línea

  
  constructor() {}

  ngOnInit(): void {}
  @Input() efos=false;

  @Input() labelsPie: Label[] = [
    /* "SAT", "AZURIAN", "ERP" */
  ];
 
  @Input() pieChartData: SingleDataSet[] = [
    /* [300, 500, 100], */
  ];
  // SingleDataSet[]
  public pieChartType: ChartType = "pie";

  @Input() chartColors: Array<any> = [
    /*{
      backgroundColor: ["#DC4C4C", "#F8D86D", "#F2AA5E", "#DC4C4C"],
      hoverBackgroundColor: [  "#088395","#d3ebc7","#24527A", "#F6D7A7"],
      borderWidth: 2,
    },*/
  ];
  //public pieChartLegend: boolean = true;
  //public barChartPlugins = [pluginDataLabels];
}
