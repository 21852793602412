 <div class="container-scroller">
    <app-navbar  *ngIf="empresas.length>0 " [empresas]="empresas"></app-navbar>
    <div class="container-fluid page-body-wrapper">
        <app-sidebar *ngIf="withOutMenus()"></app-sidebar>
        <div class="main-panel">
           <div class="content-wrapper">
               
                <router-outlet></router-outlet>
            </div>
            <app-footer></app-footer> 
        </div> 
    </div>
</div> 
