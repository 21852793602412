import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ClienteInterface, Empresa} from './cliente.interface';
import { Observable } from 'rxjs'
import {ClienteModel} from '../../../modules/auth/interfaces/beans.interfaces';

@Injectable({
    providedIn: 'root'
  })

export class ClienteService {
    
  url = environment.url;

  constructor(private http: HttpClient) { }

  getListadoCompleto(): Observable<ClienteModel[]> {//Cambiar endpoint por empresa
		return this.http.get<ClienteModel[]>(`${ this.url }clientes/full`);
	}

  count(): Observable<ClienteInterface> {
		return this.http.get<ClienteInterface>(`${ this.url }clientes`);
	}

  getPaginado(paginaActual: number, registrosPorPagina: number): Observable<ClienteInterface> {
    return this.http.get<ClienteInterface>(`${ this.url }clientes/all?pagina=${ paginaActual }&registros=${ registrosPorPagina }`);
  }

  setCliente(client: String, usuario : String, body: String): Observable<ClienteInterface> {
    return this.http.post<ClienteInterface>(`${ this.url }clientes?cliente=${ client }&usuario=${ usuario }`,body);
  }

  updateCliente(body: String, client: String, usuario: String): Observable<ClienteInterface>  {
    return this.http.put<ClienteInterface>(`${ this.url }clientes?cliente=${ client }&usuario=${ usuario }`,body);
  }

  delete(idClienteEliminado: string, client: String, usuario: String ): Observable<ClienteInterface> {
    return this.http.delete<ClienteInterface>(`${ this.url }clientes/${ idClienteEliminado }?cliente=${ client }&usuario=${ usuario }`);
 }

  getClienteRfs(cliente: string): Observable<ClienteInterface>{
    console.log('getRfc' + cliente);
    let urlNom = `${this.url}empresas/forClient?cliente=${cliente}`;
    return this.http.get<ClienteInterface>(urlNom);
  }
  getClienteEmpresa(idCliente: number ):Observable<Empresa[]>{
    return this.http.get<Empresa[]>(`${this.url}clientes/${idCliente}/empresas`);
  }

}
