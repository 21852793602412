
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Deduccione,
  HorasExtra,
  Incapacidad,
  OtroPago,
  Percepcione,
  ResponseDetalle
} from 'src/app/core/services/nomina/interfaces/nomina-detalle';
import { NominaDetalleService } from 'src/app/core/services/nomina/nomina-detalle.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
@Component({
  selector: 'app-detalle-doc-conciliado',
  templateUrl: './detalle-doc-conciliado.component.html',
  styleUrls: ['./detalle-doc-conciliado.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
          'expanded <=> collapsed',
          animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class DetalleDocConciliadoComponent implements OnInit {
  detalleDocumentoVigente: ResponseDetalle;
  activeSection: string | null = null;
  responseDetalleSat: FormGroup;
  responseDetalleErp: FormGroup;
  nominaDetails: any = [];
  selectedDocto: any = null;
  nominaDetalleTable: Percepcione[] = [];
  horasExtras: HorasExtra[] = [];
  otrosPagos: OtroPago[] = [];
  listaIncapacidad: Incapacidad[] = [];
  colspan = 0;
  isEmptyData: Boolean = false;
  doctosRelacionados: any = [];
  responseDetalleAzurian: FormGroup;
  comparativaSatPac = null;
  cabeceraCanal = null;
  conceptos: any[] = [];
  comparativaCanal: string = "";
  deducciones: Deduccione[] = [];
  constructor(private detalleNomina: NominaDetalleService, private fb: FormBuilder,private location: Location) { }

  ngOnInit(): void {
    this.buildFormResponse();
    if (this.comparativaCanal.toLocaleUpperCase() === "ERP") {
      this.comparativaCanal = this.comparativaCanal.toLocaleUpperCase();
    } else if (this.comparativaCanal.toLocaleUpperCase() === "PAC") {
      this.comparativaCanal = this.comparativaCanal.toLocaleUpperCase() + "- AZURIAN";
    }
    this.detalleNomina.getDetalleNomina(localStorage.getItem("uuid")).subscribe(data => {
      this.detalleDocumentoVigente = data;
      console.log(" detalleDocumentoVigente ",this.detalleDocumentoVigente);
      if (this.detalleDocumentoVigente ) {
        const firstDocument = this.detalleDocumentoVigente[0];

        //if (firstDocument.canal === 'SAT') {
          this.responseDetalleSat.get('uuid').setValue(firstDocument.uuid || '');
          this.responseDetalleSat.get('folio').setValue(firstDocument.folio || '');
          this.responseDetalleSat.get('version').setValue(firstDocument.version || '');
          this.responseDetalleSat.get('fechaTimbrado').setValue(firstDocument.complemento[0]?.timbreFiscalDigital?.fechaTimbrado || '');
          this.responseDetalleSat.get('fechaEmision').setValue(firstDocument.fecha || '');
          this.responseDetalleSat.get('fechaRegistro').setValue(firstDocument.createdDate || '');
          this.responseDetalleSat.get('rfcEmisor').setValue(firstDocument.emisor?.rfc || '');
          this.responseDetalleSat.get('nombreEmisor').setValue(firstDocument.emisor?.nombre || '');
          this.responseDetalleSat.get('regimenFiscalEmisor').setValue(firstDocument.emisor?.regimenFiscal || '');
          this.responseDetalleSat.get('idReceptor').setValue(firstDocument.receptor?.rfc || ''); // Asumiendo que rfc se usa como idReceptor
          this.responseDetalleSat.get('nombreReceptor').setValue(firstDocument.receptor?.nombre || '');
          this.responseDetalleSat.get('estado').setValue(firstDocument.estado || '');
          this.responseDetalleSat.get('serie').setValue(firstDocument.serie || '');
          this.responseDetalleSat.get('formaPago').setValue(firstDocument.formaPago || '');
          this.responseDetalleSat.get('condicionesPago').setValue(firstDocument.condicionesDePago || '');
          this.responseDetalleSat.get('subtotal').setValue(firstDocument.subTotal || 0);
          this.responseDetalleSat.get('descuento').setValue(firstDocument.descuento || 0);
          this.responseDetalleSat.get('moneda').setValue(firstDocument.moneda || '');
          this.responseDetalleSat.get('tipoCambio').setValue(firstDocument.tipoCambio || '');
          this.responseDetalleSat.get('exportacion').setValue(firstDocument.exportacion || '');
          this.responseDetalleSat.get('total').setValue(firstDocument.total || 0);
          this.responseDetalleSat.get('tipoComprobante').setValue(firstDocument.tipoDeComprobante || '');
          this.responseDetalleSat.get('metodoPago').setValue(firstDocument.metodoPago || '');
          this.responseDetalleSat.get('lugarExpedicion').setValue(firstDocument.lugarExpedicion || '');
          this.responseDetalleSat.get('regimenFiscalEmisor').setValue(firstDocument.emisor?.regimenFiscal || '');
          this.responseDetalleSat.get('rfcReceptor').setValue(firstDocument.receptor?.rfc || '');
          this.responseDetalleSat.get('domicilioFiscalReceptor').setValue(firstDocument.receptor?.domicilioFiscalReceptor || '');
          this.responseDetalleSat.get('residenciaFiscalReceptor').setValue(firstDocument.receptor?.residenciaFiscal || '');
          this.responseDetalleSat.get('numRegIdTrib').setValue(firstDocument.receptor?.numRegIdTrib || '');
          this.responseDetalleSat.get('regimenFiscalReceptor').setValue(firstDocument.receptor?.regimenFiscalReceptor || '');
          this.responseDetalleSat.get('usoCFDIReceptor').setValue(firstDocument.receptor?.usoCFDI || '');



          if (this.detalleDocumentoVigente[0].conceptos) {
            this.conceptos = this.detalleDocumentoVigente[0].conceptos;

          }

          this.nominaDetails = this.detalleDocumentoVigente[0].complemento[0].nomina[0];
          console.log("nominaDetails "+JSON.stringify(this.nominaDetails));

          this.doctosRelacionados = this.detalleDocumentoVigente[0]?.complemento?.[0]?.pagos?.[0]?.pago?.[0]?.doctoRelacionado || [];

          // Gestión de los datos específicos de nómina
          if (firstDocument.complemento && firstDocument.complemento.length > 0 && firstDocument.complemento[0].nomina) {
            let nominaData = firstDocument.complemento[0].nomina[0];
            this.responseDetalleSat.get('numEmpleado').setValue(nominaData?.receptor?.numEmpleado || '');

            // Si hay deducciones, actualizar el arreglo de deducciones
            if (nominaData?.deducciones && nominaData?.deducciones?.deduccion) {
              this.deducciones = nominaData?.deducciones?.deduccion;
            }
          }
        //}
      }
    });

  }

  get hasData(): boolean {
    return this.getItems('percepciones').length > 0 ||
           this.getItems('deducciones').length > 0 ||
           this.getItems('horasExtras').length > 0 ||
           this.getItems('otrosPagos').length > 0 ||
           this.getItems('incapacidades').length > 0;
  }
  

  toggleSection(section: string) {
    this.activeSection = this.activeSection === section ? null : section;
    let detalleNomina: any;
    console.log('Active section: ', this.activeSection);

    switch (this.activeSection) {
      case 'Percepciones':
        this.nominaDetalleTable = this.detalleDocumentoVigente[0].complemento[0].nomina[0].percepciones.percepcion;
        this.isEmptyData = this.nominaDetalleTable.length > 0;
        this.colspan = 5;
      break;
      case 'Deducciones':
        this.nominaDetalleTable = this.detalleDocumentoVigente[0].complemento[0].nomina[0].deducciones.deduccion;
        this.isEmptyData = this.nominaDetalleTable.length > 0;
        this.colspan = 5;
      break;
      case 'Horas Extras':
        this.horasExtras = this.detalleDocumentoVigente[0].complemento[0].nomina[0].horasExtras;
        this.isEmptyData = this.horasExtras.length > 0;
        this.colspan = 4;
      break;
      case 'Otros Pagos':
        this.otrosPagos = this.detalleDocumentoVigente[0].complemento[0].nomina[0].otrosPagos;
        this.isEmptyData = this.otrosPagos.length > 0;
        this.colspan = 4;
      break;
      case 'Incapacidades':
        this.listaIncapacidad = this.detalleDocumentoVigente[0].complemento[0].nomina[0].incapacidades;
        this.isEmptyData = this.listaIncapacidad.length > 0;
        this.colspan = 4;
      break;
    }

    console.log('detalleNomina: ', this.nominaDetalleTable);
    console.log('horasExtras: ', this.horasExtras);
    console.log('otros pagos: ', this.otrosPagos);
    console.log('Lista de incapacidad: ', this.listaIncapacidad);
    console.log('hay data: ', this.isEmptyData);
  }

  selectDocto(docto: any) {
    this.selectedDocto = docto;
  }


  buildFormResponse() {

    this.responseDetalleSat = this.fb.group({
      uuid: [''],
      folio: [''],
      version: [''],
      fechaTimbrado: [''],
      fechaEmision: [''],
      fechaRegistro: [''],
      rfcEmisor: [''],
      nombreEmisor: [''],
      regimenFiscalEmisor:[''],
      usoCFDIReceptor:[''],
      idReceptor: [''],
      nombreReceptor: [''],
      rfcReceptor: [''],
      domicilioFiscalReceptor: [''],
      regimenFiscalReceptor:[''],
      residenciaFiscalReceptor: [''],
      numRegIdTrib: [''],
      estado: [''],
      tipo: [''],
      canal: [''],
      serie: [''],
      formaPago: [''],
      condicionesPago: [''],
      subtotal: [0],
      descuento: [0],
      motivoDescuento: [''],
      moneda: [''],
      exportacion:[''],
      tipoCambio: [''],
      total: [''],
      tipoComprobante: [''],
      metodoPago: [''],
      lugarExpedicion: [''],
      numEmpleado: ['']

    });
  }

  getItems(section: string) {
    // Asegurarse de que this.nominaDetails está definido
    if (!this.nominaDetails) {
      return [];
    }
  
    const sectionData = this.nominaDetails[section];
    let items = [];
  
    if (sectionData) {
      if (section === 'percepciones') {
        items = sectionData['percepcion'] || [];
      } else if (section === 'deducciones') {
        items = sectionData['deduccion'] || [];
      } else if (section === 'otrosPagos' || section === 'incapacidades' || section === 'horasExtras') {
        items = Array.isArray(sectionData) ? sectionData : [];
      }
    }
  
    // Normalize data format
    return items.map(item => ({
      type: item.tipoPercepcion || item.tipoDeduccion || item.tipoOtroPago || item.tipo || 'N/A',
      clave: item.clave || 'N/A',
      concepto: item.concepto || item.descripcion || 'N/A',
      amount: item.importe || item.importeExento || item.monto || 0
    }));
  }
  
  goBack() {
    this.location.back();
  }

}


