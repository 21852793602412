<nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
    <a class="navbar-brand brand-logo" routerLink="/home">
      <img src="assets/images/Logo AzurianBunker_altaresolucion.png" alt="logo" />
    </a>
    <a class="navbar-brand brand-logo-mini" routerLink="/home">
      <img src="assets/images/Logo AzurianBunker_altaresolucion.png" alt="logo" />
    </a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center" [formGroup]="formCabecera">
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize"
            (click)="toggleIconOnlySidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
    <ul class="navbar-nav">

      <li class="nav-item  d-none d-lg-flex">Empresa</li>
      <li class="nav-item  d-none d-lg-flex">
        <select class="input-group-text form-control" formControlName="empresa" (change)="cambiar()">
          <option *ngFor="let emp of empresas" [ngValue]="emp.razonSocial">
            {{ emp.rfc}}
          </option>
        </select>
      </li>




      <!--  <li *ngIf="isIgual()" class="nav-item  d-none d-lg-flex">Empleado
          <input list="empleadosMaths" name="myBrowser" formControlName="rfcEmpleado" class="form-control"
            (keyup)="getEmpleado($event.target.value)" (change)="cambiar()" />
          <datalist id="empleadosMaths">

            <option *ngFor="let item of empleadosMatchs" [value]="item.idReceptor">{{item.nombreReceptor}}</option>
          </datalist>
        </li>-->
      <!-- <li class="nav-item  d-none d-lg-flex">Mes</li>
       <li class="nav-item  d-none d-lg-flex">
         <select class="input-group-text form-control" formControlName="mes" (change)="cambiar()">
           <option *ngFor="let mes of meses" [ngValue]="mes">
             {{ mes.nombre }}
           </option>
         </select>
       </li>
       <li class="nav-item  d-none d-lg-flex">Año</li>
       <li class="nav-item  d-none d-lg-flex">
         <select class="input-group-text form-control" formControlName="anio" (change)="cambiar()">
           <option *ngFor="let anio of anios" [ngValue]="anio">
             {{ anio }}
           </option>
         </select>
       </li>-->





    </ul>

    <ul class="navbar-nav navbar-nav-right">


      <li class="nav-item dropdown d-none d-xl-inline-flex" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="UserDropdown" ngbDropdownToggle>
          <img class="img-xs rounded-circle" src="assets/images/faces/user32.png" alt="Profile image">
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" ngbDropdownMenu aria-labelledby="UserDropdown">
          <div class="dropdown-header text-center">
            <img class="img-md rounded-circle" src="assets/images/faces/user32.png" alt="Profile image" />
            <p class="mb-1 mt-3 font-weight-semibold">{{ username }}</p>
            <p class="font-weight-light text-muted mb-0">{{ correo }}</p>
          </div>
          <a class="dropdown-item">Perfil <span class="badge badge-pill badge-danger">1</span><i
                  class="dropdown-item-icon ti-dashboard"></i></a>
          <a class="dropdown-item" (click)="clientes(ventanaCliente)">Clientes</a>
          <a class="dropdown-item">Notificaciones<i class="dropdown-item-icon ti-comment-alt"></i></a>
          <a class="dropdown-item">Actividad<i class="dropdown-item-icon ti-location-arrow"></i></a>
          <a class="dropdown-item">¿Preguntas?<i class="dropdown-item-icon ti-help-alt"></i></a>
          <a class="dropdown-item" (click)="salir()">
            Cerrar Sesión<i class="dropdown-item-icon ti-power-off"></i></a>
        </div>
      </li>
    </ul>

  </div>
</nav>

<ng-template #ventanaCliente let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-center">Cambio de cliente</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="cambioCliente()" #clienteForm="ngForm" class="forms-sample">
    <div class="modal-body">
      <p>Si desea cambiar a otro cliente solo seleccionelo y de "Aceptar"</p>
      <div>
        <select class="form-control" id="idempresa" name="clie" [(ngModel)]="clie.id">
          <option *ngFor="let client of clients" value="{{ client.id }}">
            {{ client.id }}
          </option>
        </select>
      </div>
    </div>
    <div class="modal-footer btn-group-sm">
      <button type="submit" class="btn btn-success">Aceptar</button>
      <button type="button" class="btn btn-danger" (click)="close()">
        Cancelar
      </button>
    </div>
  </form>
</ng-template>
