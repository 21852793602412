import { Component, OnInit } from '@angular/core';
import {ClienteService} from "../../../../core/services/cliente/cliente.service";
import {ClienteModel} from "../../interfaces/beans.interfaces";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Empresa} from "../../../../core/services/cliente/cliente.interface";
import {TokenStorageService} from "../../../../core/services/auth/token-storage.service";
import {Router} from "@angular/router";
import {Usuario} from "../../../../core/services/usuario/usuario.interfaces";
import {UsuarioService} from '../../../../core/services/usuario/usuario.service';
import {SearchStorageService} from '../../../../core/services/search/search-storage.service';

@Component({
  selector: 'app-select-empresa',
  templateUrl: './select-empresa.component.html',
  styleUrls: ['./select-empresa.component.scss']
})
export class SelectEmpresaComponent implements OnInit {
  empresas : Empresa[];
  formSelectCliente: FormGroup;

  constructor( private tokenService: TokenStorageService, private fb: FormBuilder, private modal: NgbModal, private storageService: TokenStorageService, private router: Router, public activeModal: NgbActiveModal, private usuarioService: UsuarioService) { }





  ngOnInit(): void {


    this.getEmpresaForUser();
    this.buildFormSelect();

  }


  getEmpresaForUser(){
    console.log('getEmpresaForUser');
    const user : Usuario= this.storageService.getUser();
    console.log('getEmresaUsuario' , user.assginedEmpresas);
    if( user.assginedEmpresas.length>0){
      this.empresas=this.storageService.getUser().assginedEmpresas;
      console.log('empresas', this.empresas);
      if( user.assginedEmpresas.length==1){
        let selectedRfc = user.assginedEmpresas[0].rfc;
        localStorage.setItem("rfc",  user.assginedEmpresas[0].rfc);
        localStorage.setItem("idEmpresa",  String(user.assginedEmpresas[0].id));
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth() + 1; 
        if (!localStorage.getItem("anio")) {
          localStorage.setItem("anio", String(currentYear));
        }
        if (!localStorage.getItem("mes")) {
          // Ajusta el mes actual restando uno y manejando el caso de enero
          if (currentMonth === 1) { // Si es enero
            currentMonth = 12; // Poner como diciembre
            localStorage.setItem("anio", String(currentYear - 1)); // Reducir el año en 1
          } else {
            currentMonth -= 1; // Reducir el mes en 1
          }
          localStorage.setItem("mes", String(currentMonth));
        }

        // Cierra la modal y pasa el valor seleccionado como resultado
        this.tokenEmpresa();
        setTimeout(() => {
            this.activeModal.close({ seleccionado: true, rfc: selectedRfc });
        }, 100);

}
    }
  }

  eleccionEmpresa() {
    console.log("Eleccion cliente");
    const user : Usuario= this.storageService.getUser();
    const selectedRfc = this.formSelectCliente.get('selectEmpresa').value;
    console.log('rfc seleccionada', selectedRfc);
    console.warn("empresa mes");
    this.empresas.forEach(empresa => {
      if (empresa.rfc === selectedRfc) {
        console.log('empresa seleccionada', empresa);
        localStorage.setItem("rfc", empresa.rfc);
        localStorage.setItem("idEmpresa", String(empresa.id));
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth() + 1; 
        if (!localStorage.getItem("anio")) {
          localStorage.setItem("anio", String(currentYear));
        }
        if (!localStorage.getItem("mes")) {
          // Ajusta el mes actual restando uno y manejando el caso de enero
          if (currentMonth === 1) { // Si es enero
            currentMonth = 12; // Poner como diciembre
            localStorage.setItem("anio", String(currentYear - 1)); // Reducir el año en 1
          } else {
            currentMonth -= 1; // Reducir el mes en 1
          }
          localStorage.setItem("mes", String(currentMonth));
        }

      }
    });

    this.tokenEmpresa();
        /*localStorage.setItem("rfc",  user.assginedEmpresas[0].rfc);
    localStorage.setItem("idEmpresa",  String(user.assginedEmpresas[0].id));
    localStorage.setItem("anio", "2017");
    localStorage.setItem("mes", "1");*/

    //this.tokenEmpresa();
    // Cierra la modal y pasa el valor seleccionado como resultado
    this.activeModal.close({ seleccionado: true, rfc: selectedRfc });

    // Navegar a otra ruta
  }

  close(){
    this.modal.dismissAll();
}
buildFormSelect() {
  /*this.formSelectCliente= this.fb.group({
    selectEmpresa: [this.empresas[0]]
  })*/
  this.formSelectCliente = this.fb.group({
    selectEmpresa: [this.empresas.length > 0 ? this.empresas[0].rfc : null]
  });
}
  tokenEmpresa(){
    const empresa = Number(localStorage.getItem("idEmpresa"));
    console.log(" empresa " + empresa);
    this.usuarioService.tokenByEmpresa(empresa).subscribe(
        response => {
          console.log("Respuesta recibida:", response);

          // Extrayendo el token del objeto de respuesta
          const token = response.token;

          console.log("Token recibido:", token);
          localStorage.setItem("tokenByEmpresa", token);  // Almacenando el token en localStorage
          this.tokenService.saveToken(token);


        },
        error => {
          console.error("Error al obtener el token:", error);
          // Manejo del error aquí
        }
    );
  }
}
