import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {DataCliente, DataEmpleado} from '../cliente/cliente.interface';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoObserverService {

  private _dataEmpleado$= new BehaviorSubject<DataEmpleado>({} as DataEmpleado);
  
  clienteOb(dataEmpleado: DataEmpleado) {
    if( this._dataEmpleado$.observers === null) {
      this._dataEmpleado$ = new BehaviorSubject<DataEmpleado>({} as DataEmpleado);
      console.log("value del observable", this._dataEmpleado$);
    }
    this._dataEmpleado$.next(dataEmpleado);
  }

  getClientes$(): Observable<DataEmpleado> {
    
    return this._dataEmpleado$.asObservable();
  }

  getCliente(): Observable<DataEmpleado> {
    return this._dataEmpleado$;
  }

  getUnsuscirbe() {
    return this._dataEmpleado$.unsubscribe();
  }
}
