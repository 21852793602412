import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProtegerutaGuard } from './core/guards/protegeruta.guard';
import { Page404Component } from './shared/components/page404/page404.component';
import {home} from 'ionicons/icons';
import {LayoutHomeComponent} from './layout/components/layout-home/layout-home.component';
import {RolGuardServiceGuard} from './core/guards/rol-guard-service.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: 'home',
    /* data: { breadcrumb: 'home'},  */
    loadChildren: () =>
    import('./modules/home/home.module').then((m) => m.HomeModule),

  },


  {
    path: 'config',
    loadChildren: () => import('./modules/config/config.module').then((m) => m.ConfigModule),

  },
  {
    path: 'sat',
    loadChildren: () =>
      import('./modules/descarga-sat/descarga-sat.module').then((m) => m.DescargaSatModule),
    // canLoad: [ProtegerutaGuard]
  },
  {
    path: '**',
    component: Page404Component,
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
        useHash: true,
    }),
  ],
  exports: [RouterModule, NgbModule],
})
export class AppRoutingModule { }
