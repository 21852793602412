import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment  } from 'src/environments/environment';
import { Propiedades } from 'src/app/modules/auth/interfaces/beans.interfaces';
import { Observable } from 'rxjs';
import { PropiedadesInterfas } from '../propiedades/propiedades.interfaces';

@Injectable({
    providedIn: 'root'
  })

export class ConciliacionService {
    
    url = environment.url;

    constructor(private http: HttpClient) { }

    SatAzurian(uuid:string, rfc: string, anio:number,mes:number)  {
       let body:any;
      return this.http.post<PropiedadesInterfas> (`${ this.url }conciliacion/inexistente/Sat/${ rfc }/${ anio }/${ mes}?uuid=${uuid}`,body);
    }

    actualizarSatAzurian(uuid:string, rfc: string, anio:number,mes:number) {
        let body:any;
        return this.http.put<PropiedadesInterfas> (`${ this.url }conciliacion/igualar/pac/${ rfc }/${ anio }/${ mes }?uuid=${uuid}`,body);  
    }

    actualizarData(rfc: string, anio:number,mes:number) {
        return this.http.get<PropiedadesInterfas> (`${ this.url }conciliacion/cancelar/Sat/${ rfc }/${ anio }/${ mes }`);  
    }

    SatAzurianMasuvo(uuids:string[],rfc: string, anio:number,mes:number)  {
      console.log("SatAzurianMasuvo {}", );
      let body = JSON.stringify(uuids);
      console.log();
      return this.http.post<PropiedadesInterfas> (`${ this.url }conciliacion/inexistente/masivos/Sat/${ rfc}/${ anio}/${ mes}`,body);
    }

    actualizarSatAzurianMasivo(uuids: string[],rfc: string, anio:number,mes:number) {
        let body = JSON.stringify(uuids);
        console.log(body);
        console.log(uuids);
        return this.http.put<PropiedadesInterfas> (`${ this.url }conciliacion/igualar/masivos/Azurian/${ rfc}/${ anio}/${ mes}`,uuids);  
    }
}