import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { NominaDetailsService } from 'src/app/core/services/nomina/nomina-details.service';

@Component({
  selector: 'app-bar-chart-efos',
  templateUrl: './bar-chart-efos.component.html',
  styleUrls: ['./bar-chart-efos.component.scss'],
})

export class BarChartEfosComponent implements OnInit {
  @Input() intervalo = 0;
  @Input() labels = 0;
  @Input() diagnostico = false;
  @Input() showLegend: boolean = true;
  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
     scales: {
      xAxes: [{
      }],
      yAxes: [
        {
          ticks: {
            min: 0,
            autoSkip: false,  
            stepSize: this.intervalo,
            fontColor: '#878e94',
            beginAtZero: true,
            maxRotation: 0,  
            minRotation: 0 

          },
          gridLines: {
            color: '#b9c0d3',
            zeroLineColor: '#b9c0d3',
            display: false,
            drawBorder: false
          }
        },
      ],
    },
    /*legend: {
      position: 'bottom' // Puedes ajustar según sea necesario
    }*/


  };
  @Input() barChartLabels: Label[] = [
  ];
   barChartType: ChartType = 'bar';

  barChartLegend = true;
  barChartPlugins = [];

  @Input() barChartData: ChartDataSets[] = [

  ];

  constructor(private route: Router, nominaDetails: NominaDetailsService) { }
  ngOnInit(): void {
  }
}
