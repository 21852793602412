<div class="modal-header">
    <h4 class="modal-title text-center font-weight-semibold" style="font-family: Mulish;font-size: 19px;">Seleccione una empresa</h4>
</div>
<div class="modal-body">
    <form class="form-sample">
        <div class="col-lg-12">
            <div>
                <form [formGroup]="formSelectCliente" >
                    <select class=" form-control" id="idCliente"  formControlName="selectEmpresa">
                        <option *ngFor="let cliente of empresas"  [value] = "cliente.rfc" >{{cliente.razonSocial}}</option>
                    </select>
                </form>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer btn-group-sm">
    <button type="button" class="btn btn-custom" (click)="eleccionEmpresa()">Aceptar</button>
</div>
