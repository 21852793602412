import { Component, Input, OnInit } from '@angular/core';
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClienteService } from "src/app/core/services/cliente/cliente.service";
import { Cliente, ClienteModel } from "src/app/modules/auth/interfaces/beans.interfaces";
import { LoginService } from "src/app/core/services/login/login.service";
import { Catalogo } from "src/app/core/services/util.intefaces";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ClienteObserverService } from "src/app/core/services/cliente/cliente-observer.service";
import {DataCliente, Empresa} from "src/app/core/services/cliente/cliente.interface";
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Empleado } from 'src/app/core/services/nomina/interfaces/nomina-empleado';
import { EmpleadoObserverService } from 'src/app/core/services/empleado/empleado-observer.service';
import { EmpleadoService } from 'src/app/core/services/empleado/empleado.service';
@Component({
  selector: "app-navbar-admin",
  templateUrl: "./navbar-admin.component.html",
  styleUrls: ["./navbar-admin.component.scss"],
})
export class NavbarAdminComponent implements OnInit {
  modal: any;
  clients: ClienteModel[] = [];
  clie: Cliente;

  username = "";
  correo = "";

  titulo = "";
  @Input() empresas: Empresa[];

  empresaSeleccion = "";
  cliente = "";
  fechaInicio: Date;
  fechaFinal: Date;
  meses: Catalogo[] = [];
  anios: number[] = [];
  mes: Catalogo = null;
  anio: number = null;
  formCabecera: FormGroup;
  clienteDataForm: DataCliente;
  public iconOnlyToggled = false;
  public sidebarToggled = false;

  searchEmpleado$ = new BehaviorSubject<string>("");
  empleadosMatchs: Empleado[] = [];
  employeesFiltered: Observable<Empleado[]>;
  nombre:string=null;


  private OnDestroy$ = new Subject();
  toggleRightSidebar() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  toggleIconOnlySidebar() {
    this.iconOnlyToggled = !this.iconOnlyToggled;
    if (this.iconOnlyToggled) {
      document.querySelector("body").classList.add("sidebar-icon-only");
    } else {
      document.querySelector("body").classList.remove("sidebar-icon-only");
    }
  }

  constructor(
    config: NgbDropdownConfig,
    private router: Router,
    private modalService: NgbModal,
    private clienteService: ClienteService,
    private spinner: NgxSpinnerService,
    private login: LoginService,
    private formGroup: FormBuilder,
    private clienteObv: ClienteObserverService,
    private empleadoObv: EmpleadoObserverService,
    private route: ActivatedRoute,
    private empleadoService: EmpleadoService
  ) {
    config.placement = "bottom-right";
    this.username = localStorage.getItem("nombre");
    this.correo = localStorage.getItem("idUsuario");
  }
  ngOnDestroy(): void {
    console.log("observador empleado destroys");

  }


  ngOnInit() {

    this.buildFormCabecera();
    localStorage.setItem("rfc", this.empresas[0].rfc);
    localStorage.setItem("idEmpresa", this.empresas[0].id + '');


    this.titulo = "dasbopard";

  }

  empresaClick() {
    console.log("cambio de empresa " + this.empresaSeleccion);
    this.busqueda();
  }

  mesSeleccion() {
    console.log("Mes Seleccionado");
  }

  anio1Click(dato: any) {
    this.fechaInicio = dato;
    console.log("cambio de anio " + this.fechaInicio.toString());
    this.busqueda();
  }

  anio2Click(dato: any) {
    this.fechaFinal = dato;
    console.log("cambio de anio final " + this.fechaFinal.toString());
    this.busqueda();
  }

  busqueda() {
    console.log("EMPRESA" + this.empresaSeleccion);
    console.log("INICIO" + this.fechaInicio);
    console.log("TERMINO" + this.fechaFinal);

    localStorage.setItem("fechaInicio", this.fechaInicio.toString());
    localStorage.setItem("fechaFinal", this.fechaFinal.toString());
    localStorage.setItem("empresa", this.empresaSeleccion);

    console.log("INICIO" + localStorage.getItem("fechaInicio"));
    console.log("TERMINO" + localStorage.getItem("fechaFinal"));

    window.location.reload();
  }

  salir() {
    console.log("Saliendo de la sesion");
    this.login.cerrarSession();
    this.router.navigate(['/auth/login']);
  }

  goModificarContrasena() {
    //this.router.navigate(['administracion/modificarContrasena/primerLogin']);
    this.router.navigate(["administracion/modificarContrasena/nuevaPass"]);
  }

  goModificarCuenta() {
    this.router.navigate(["administracion/primerLogin"]);
  }

  clientes(content: any) {
    this.clients = [];
    this.clie = new Cliente();
    this.clie.id = localStorage.getItem("cliente");
    //this.spinner.show();
    this.clienteService.getListadoCompleto().subscribe((data) => {
      //if (data.httpStatus === 200) {
        this.clients = data;
        this.modalService.open(content, {
          ariaLabelledBy: "modal-basic-title",
        });
     // }

    });
  }

  close() {
    this.modalService.dismissAll();
  }

  cambioCliente() {
    //console.log( this.clie.id);
    //this.spinner.show();
    localStorage.setItem("cliente", this.clie.id);
    window.location.reload();
  }



  cambiar() {
    console.log("clientedataform", this.formCabecera.value)
    this.clienteDataForm = this.formCabecera.value;
    console.log("clientedataform", this.formCabecera.value)
    let tipo = localStorage.getItem("subMenuEmitida");
    if (tipo === 'nominaEmpleado') {
      this.empleadoObv.clienteOb(this.clienteDataForm);
    } else if (tipo === "nominaEmpresa") {
      console.log("nomina empresa", tipo)
      //this.clienteObv.clienteOb(this.clienteDataForm);
    }
  }


  private buildFormCabecera(): void {
    this.formCabecera = this.formGroup.group({
      cliente: [this.cliente],
      mes: [this.meses[10]],
      anio: [this.anios[0]],
      empresa: [this.empresas[0]],
      rfcEmpleado: []
    });
  }

  getEmpresaCargada() : number{
    const rfcCargada= localStorage.getItem('rfc');
   return this.empresas.findIndex( empresa => empresa.rfc===rfcCargada);
  }

  isIgual() {
    let tipo = localStorage.getItem("subMenuEmitida");
    if (tipo === 'nominaEmpleado') {
      localStorage.setItem("rfcEmpleado", this.formCabecera.get("rfcEmpleado").value);
      return true;
    }
    return false;
  }

  getEmpleado(value: string) {
    if (value.trim().length > 0 && value !== "") {
      this.searchEmpleado$
        .pipe(
          debounceTime(1000), 
          /*distinctUntilChanged(), */
          takeUntil(this.OnDestroy$),
        ).subscribe(data => {
          this.empleadoService.getEmpleado(value).subscribe(data => {
            this.empleadosMatchs = data;
           })

        })
    } 
  }

}
