
<div class="table-responsive">
    <h4>DIFERENCIA DE MONTOS<i class="mdi mdi-file-document-box text-success" type="button" ngbTooltip="Descarga de Reporte" (click)="descarga()"></i></h4>
    <div class="pagination-container">
        <div class="pagination-controls">
          <div class="btn-toolbar mb-1" role="toolbar">
            <div class="btn-group-sm ml-1" role="group">
              <button type="button" class="btn boton-verde-personalizado icono-blanco" (click)="paginaInicial()" [disabled]="paginaActual === 1">
                <b><span><i class="mdi mdi-skip-backward"></i></span></b>
              </button>
            </div>
            <div class="btn-group-sm ml-1" role="group">
              <button type="button" class="btn boton-verde-personalizado icono-blanco" (click)="paginaAnterior()" [disabled]="paginaActual === 1">
                <b><span><i class="mdi mdi-skip-previous"></i></span></b>
              </button>
            </div>
            <div class="form-group ml-1">
              <input type="number" class="form-control form-control-sm pagination-input" [(ngModel)]="paginaActual"
                     name="paginaActual" min="1" [max]="totalPaginas" (change)="getDocumentos(requetEmitido, tipoComprobante)"
                     style="width: 60px; text-align: center;">
            </div>
            <div class="btn-group-sm ml-1" role="group">
              <button type="button" class="btn boton-verde-personalizado icono-blanco" (click)="paginaSiguiente()" [disabled]="paginaActual === totalPaginas">
                <b><span><i class="mdi mdi-skip-next"></i></span></b>
              </button>
            </div>
            <div class="btn-group-sm ml-1" role="group">
              <button type="button" class="btn boton-verde-personalizado icono-blanco" (click)="paginaFinal()" [disabled]="paginaActual === totalPaginas">
                <b><span><i class="mdi mdi-skip-forward"></i></span></b>
              </button>
            </div>
          </div>
          <div class="form-group ml-1">
            <select [(ngModel)]="registrosPorPagina" name="registrosPorPagina"
                    (change)="onPageSizeChange($event.target.value)" class="form-control form-control-sm">
              <option value="2">2</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </div>
    <table class="table table-sm table-striped">
        <thead>
        <tr class="text-center head-title">
            <th colspan="5">
            </th>
        </tr>
        <tr *ngIf="contador()" class="text-right head-title">
            <th colspan="5">
                <h4>Conciliar la cantidad de {{documentosSeleccionados.length}} Documentos<i class="mdi mdi-alert text-danger" type="button" ngbTooltip="Conciliar Todos" (click)="docsValid()"></i></h4>
            </th>
        </tr>
        <tr class="text-center head-column">
            <th scope="row">
                <input type="checkbox" value="" id="flexCheckDefault" (change)="selectAll($event)" />
            </th>
            <th scope="col">UUID</th>
            <th scope="col">RFC</th>
            <th scope="col">RECEPTOR</th>
            <th scope="col">TOTAL</th>
            <th scope="col">OPCIONES</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let doc of docsDiferenciaMount">
            <th scope="row">
                <div class="form-check">
                    <input type="checkbox" id="flexCheckDefault" [(ngModel)]="doc.checked" (change)="seleccionado($event,doc.uuid)" />
                </div>
            </th>
            <th class="text-center">{{doc.uuid}}</th>
            <td class="text-center">{{doc.rfcEmisor}}</td>
            <td class="text-center">{{doc.nombreReceptor}}</td>
            <td class="text-center">{{doc.comprobante?.total | currency}}</td>
            <td>
                <i class="mdi mdi-folder-remove text-danger" type="button" ngbTooltip="Cancelar doumento en el SAT" (click)="conciliacion(doc)"></i>
                <i class="mdi mdi-account-search text-success" type="button" ngbTooltip="Detalles Nomina" (click)="visualizar(doc.uuid)"></i>
            </td>
        </tr>
        </tbody>
    </table>
</div>
