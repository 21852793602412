<div *ngIf="lineChartData.length===0; else divGrafica" class="alert alert-info">
  Cargando datos ...
  </div>
 

<div class="flex">
  <div class="flex-item">
    <ng-template #divGrafica>

      <div style="display: block;">
        <div>
          <canvas *ngIf="documento"
          baseChart
          [datasets]="lineChartData"
          [labels]="labels"
          [options]="barChartOptions"
          [chartType]="lineChartType"
          (chartClick)="clickGrafica($event)"></canvas>
        </div>
    
           
    </div>
    </ng-template>
    
  </div>
</div>
