
<div *ngIf="barChartData.length===0; else divGrafica" class="alert alert-info">
    Cargando datos ...
    </div>
    <ng-template #divGrafica>
      <div><canvas  baseChart  
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas>
    </div>
    </ng-template>
