import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AppRoutingModule } from './app-routing.module';
import { DragulaModule } from 'ng2-dragula';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule} from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { InterceptorService } from './core/interceptors/interceptor.service';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LayoutAdminComponent } from './layout/components/layout-admin/layout-admin.component';
import { LayoutHomeComponent } from './layout/components/layout-home/layout-home.component';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {RolGuardServiceGuard} from './core/guards/rol-guard-service.guard';
import { ModalModule } from 'ngx-bootstrap/modal';
//import { DuplicadosComponent } from './modules/components/duplicados/duplicados.component';






@NgModule({

    declarations: [AppComponent, LayoutAdminComponent, LayoutHomeComponent],
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        RouterModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        HttpClientModule,
        NgxSpinnerModule,
        SharedModule,
        DragulaModule.forRoot(),
        NgCircleProgressModule.forRoot({
            radius: 60,
            outerStrokeWidth: 10,
            innerStrokeWidth: 5,
            showBackground: false,
            startFromZero: false,
        }),
        ModalModule.forRoot(),
    ],

    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        RolGuardServiceGuard,
    ],
    bootstrap: [AppComponent],
    exports: [

    ]
})
export class AppModule {}
