export class Request {
  rfc: string;
}


export class RequestDetails {
  idEmpresa: number;
  mes: number;
  anio: number;
  rfc?: string;
}

export class RequestDetailsEmpleado {
  rfcEmisor: string;
  mes: number;
  anio: number;
  rfcReceptor: string;
}
