
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Conciliacion } from 'src/app/core/services/nomina/interfaces/nomina-details';
import { ConciliacionService } from 'src/app/core/services/conciliacion/conciliacion.service';
import { ToastrService } from 'ngx-toastr';
import { NominaDetailsService } from 'src/app/core/services/nomina/nomina-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReporteService } from 'src/app/core/services/reporte/reporte.service';
import * as FileSaver from 'file-saver';
import Swal from "sweetalert2";
import { ClienteObserverService } from 'src/app/core/services/cliente/cliente-observer.service';
import { RequestDetails } from 'src/app/core/services/nomina/interfaces/beans.interfaces';


@Component({
  selector: 'app-conciliacion-doc-dif-state',
  templateUrl: './conciliacion-doc-dif-state.component.html',
  styleUrls: ['./conciliacion-doc-dif-state.component.scss']
})
export class ConciliacionDocDifStateComponent implements OnInit {
  @Input() docsStatus: Conciliacion[] | any = [];
  groupedDocs: any[] = [];
  filteredDocs: any[] = [];
  searchTerm: string = '';
  typeAlert: string;
  messageAlert: string;
  documentosSeleccionados: string[] = [];
  //PAGINADOR
  registrosPorPagina = 10;
  paginaActual = 1;
  totalRegistros = 0;
  totalPaginas = 0;
  requetEmitido: any;
  tipoComprobante: string = null;
  @Output()  existeConciliacion = new EventEmitter<boolean>();
  @Input() sentido: string;
  constructor(private conciliacionService: ConciliacionService,
    private nominaDetails: NominaDetailsService,
    private route: ActivatedRoute,
    private clienteObservador: ClienteObserverService,

              private router: Router, private reporteService: ReporteService, private clienteOb:ClienteObserverService) { 
                this.tipoComprobante = this.route.snapshot.paramMap.get("tipoComprobante");

              }

  ngOnInit(): void {
    console.log("docsStatus", this.docsStatus.length);
    this.clienteObservador.getClientes$().subscribe(dataCliente => {
      this.requetEmitido = new RequestDetails();
      this.requetEmitido.rfc = dataCliente.empresa;
      this.requetEmitido.anio = dataCliente.anio;
      this.requetEmitido.mes = dataCliente.mes.id;
      this.getDocsStatusCount(this.requetEmitido,this.tipoComprobante);
      this.groupDocsByUuid();
      this.filterDocs();




    });
  }

  conciliacion(documento: Conciliacion) {
    console.log('VALOR');
    console.log(documento);
    if(documento.estado === 'Cancelado' && documento.canal === 'PAC') {
      this.cambiarEstadoSat(documento);
    } else {
      this.servicioConciliacion(documento);
    }


  }

  servicioConciliacion(documento: Conciliacion) {
    this.clienteOb.getClientes$().subscribe(dataClient=>{
      this.conciliacionService.actualizarSatAzurian(documento.uuid,dataClient.empresa,dataClient.anio,dataClient.mes.id).subscribe(data => {
        console.log("Se va a cargar el documento en Azurian" , data.httpStatus);

        if(data.httpStatus === 201){
          this.statusOK(documento);
        } else {
          Swal.fire('Error', data.messageDetail, 'error')
        }
      });
    })
  }

  statusOK(documento: Conciliacion) {
    Swal.fire({
      title: "Conciliado con exito",
      icon: "success",
      confirmButtonColor: " #73b72b",
      confirmButtonText: "Salir",
    }).then((resultado) => {
      if (resultado.isConfirmed) {

        this.eliminarValor(documento);
      }
    });
  }

  eliminarValor(documento) {
    this.filteredDocs = this.filteredDocs.filter(item => item.uuid !== documento.uuid);
    this.notifyIfExist();
  }

  cambiarEstadoSat(documento: Conciliacion) {
    Swal.fire({
      title: "El documento que va a conciliar esta cancelado en PAC pero vigente en el SAT",
      text: "¿Elija que tratamiento desea?",
      icon: "warning",
      confirmButtonColor: " #73b72b",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Vigente en PAC",
      denyButtonText: "Cancelar en SAT",
      cancelButtonText: "Salir",
    }).then((resultado) => {
      if (resultado.isConfirmed) {
        this.servicioConciliacion(documento);
      } else if (resultado.isDenied) {
        // ESTA SIMULADO LA CANCELACION
        this.statusOK(documento);
      }
    });
  }


  showMessageAlert(tipo: string, mensaje: string) {
    this.messageAlert = mensaje;
    this.typeAlert = tipo;
    setTimeout(() => (this.messageAlert = null), 5000);
  }

  descarga() {

    this.reporteService.descargaReporte(this.docsStatus).subscribe(data => {
      if(data.httpStatus===200 ){

        let fileBlob = this.base64ToArrayBuffer(data.reporte);
        let archivo = new Blob([fileBlob], { type: 'application/xlsx' });
        console.log(archivo);
        FileSaver.saveAs(archivo, 'Reporte.xlsx');
      } else {
        this.showMessageAlert("info", data.messageDetail);
      }
    });
  }

  base64ToArrayBuffer(base64: any): any {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }


  contador(): boolean {
    if (
        (this.filteredDocs.length > 0)) {
      return true;
    } else {
      return false;
    }
  }

  docsValid() {
    Swal.fire({
      title: "Documento válidos ",
      text: "¿Seguro que todos los documentos seleccionados, son válidos?",
      icon: "warning",
      confirmButtonColor: " #73b72b",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then((resultado) => {
      if (resultado.value) {
        console.log(this.documentosSeleccionados);
        this.conciliacionMasiva(this.documentosSeleccionados);
      }
    });
  }

  conciliacionMasiva(uuids: string[]) {
    this.clienteOb.getClientes$().subscribe((dataClient) => {
      this.conciliacionService.actualizarSatAzurianMasivo(uuids,dataClient.empresa,
          dataClient.anio,
          dataClient.mes.id).subscribe(data => {
        console.log("Se va a cargar el documento en Azurian masiva" , data.httpStatus);
        if(data.httpStatus===201  ){
          this.showMessageAlert("info", data.messageDetail);
          this.router.navigate(["home/nomina-empresa-emitidas/dashboard-empresa"]);
        } else {
          this.showMessageAlert("info", data.messageDetail);
        }
      });
    });

  }


  selectAll(event: any) {
    console.log("event", event.target.checked);
    if (event.target.checked) {
      this.docsStatus.map((doc) => {
        doc.checked = true;
        this.documentosSeleccionados.push(doc.uuid);
      });
    } else {
      this.documentosSeleccionados = [];
      this.docsStatus.map((doc) => {
        doc.checked = false;
      });
    }
  }

  seleccionado(event: any, uuid: string) {
    if (event.target.checked) {
      this.documentosSeleccionados.push(uuid);
    } else {
      this.documentosSeleccionados = this.documentosSeleccionados.filter(
          (m) => m != uuid
      );
    }
  }

  notifyIfExist():   void {
    if (this.filteredDocs.length > 0) {
      console.log("existe conciliacion desde el componente diferencia de stados", this.filteredDocs.length);
      this.existeConciliacion.emit(true);
    } else {
      this.existeConciliacion.emit(false);
    }
  }

  getDocsStatus(request: RequestDetails, tipoComprobante: string): void {
    this.nominaDetails.getActualizacionStatus(request, 'pac', tipoComprobante,this.paginaActual - 1, this.registrosPorPagina, this.sentido).subscribe(data => {
      this.docsStatus = data;
      this.groupDocsByUuid();     
      this.filterDocs();


    }, error => {
      console.log("error docuemntos status", error.status);
    });
  }

  groupDocsByUuid() {
    const groups = this.docsStatus.reduce((acc, doc) => {
      (acc[doc.uuid] = acc[doc.uuid] || []).push(doc);
      return acc;
    }, {});

    this.groupedDocs = Object.keys(groups).map(uuid => ({
      uuid,
      docs: groups[uuid].map(doc => ({
        uuid: doc.uuid,
        rfcEmisor: doc.rfcEmisor,
        nombreReceptor: doc.nombreReceptor,
        comprobante: doc.comprobante,
        estado: doc.estado,
        canal: doc.canal,
        checked: false
      })),
      expanded: false,
      checked: false
    }));

    console.log('groupedDocs:', this.groupedDocs); // Añade esto para depurar
  }

  getDocsStatusCount(request: RequestDetails, tipoComprobante: string): void {
    this.nominaDetails.getActualizacionStatusCount(request, 'pac', tipoComprobante, this.sentido).subscribe(data => {
      this.totalRegistros = data['numeroRegistros'];
      this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPorPagina);
    }, error => {
      console.log('error documentos status', error.status);
    });
  }

  paginaInicial() {
    this.paginaActual = 1;
    this.getDocsStatus(this.requetEmitido, this.tipoComprobante);
  }

  paginaAnterior() {
    if (this.paginaActual > 1) {
      this.paginaActual--;
      this.getDocsStatus(this.requetEmitido, this.tipoComprobante);
    }
  }

  paginaSiguiente() {
    if (this.paginaActual < this.totalPaginas) {
      this.paginaActual++;
      this.getDocsStatus(this.requetEmitido, this.tipoComprobante);
    }
  }

  paginaFinal() {
    this.paginaActual = this.totalPaginas;
    this.getDocsStatus(this.requetEmitido, this.tipoComprobante);
  }

  onPageSizeChange(size: number) {
    this.registrosPorPagina = size;
    this.paginaActual = 1;
    this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPorPagina);
    this.getDocsStatus(this.requetEmitido, this.tipoComprobante);
  }

  toggleGroup(uuid: string) {
    const group = this.groupedDocs.find(g => g.uuid === uuid);
    if (group) {
      group.expanded = !group.expanded;
    }
  }

  filterDocs() {
    if (!this.searchTerm) {
      this.filteredDocs = this.groupedDocs;
    } else {
      const searchTermLower = this.searchTerm.toLowerCase();
      this.filteredDocs = this.groupedDocs.filter(group => {
        return group.uuid?.toLowerCase().includes(searchTermLower) ||
               group.docs.some(doc =>
                 doc.rfcEmisor?.toLowerCase().includes(searchTermLower) ||
                 doc.nombreReceptor?.toLowerCase().includes(searchTermLower) ||
                 doc.comprobante?.total.toString().includes(searchTermLower));
      });
    }
  }



}
