<nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
    <a class="navbar-brand brand-logo" routerLink="/home">
      <img src="assets/images/Logo AzurianBunker_altaresolucion.png" alt="logo" />
    </a>
    <a class="navbar-brand brand-logo-mini" routerLink="/home">
      <img src="assets/images/Logo AzurianBunker_altaresolucion.png" alt="logo" />
    </a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center" [formGroup]="formCabecera">
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize"
            (click)="toggleIconOnlySidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
    <ul class="navbar-nav">

      <li class="nav-item  d-none d-lg-flex">Empresa</li>
      <li class="nav-item  d-none d-lg-flex">
        <select class="input-group-text form-control" formControlName="empresa" (change)="cambiar()">
          <option *ngFor="let emp of empresas" [ngValue]="emp">
            {{ emp.razonSocial}}
          </option>
        </select>
      </li>




      <!--  <li *ngIf="isIgual()" class="nav-item  d-none d-lg-flex">Empleado
          <input list="empleadosMaths" name="myBrowser" formControlName="rfcEmpleado" class="form-control"
            (keyup)="getEmpleado($event.target.value)" (change)="cambiar()" />
          <datalist id="empleadosMaths">

            <option *ngFor="let item of empleadosMatchs" [value]="item.idReceptor">{{item.nombreReceptor}}</option>
          </datalist>
        </li>-->
      <!-- <li class="nav-item  d-none d-lg-flex">Mes</li>
       <li class="nav-item  d-none d-lg-flex">
         <select class="input-group-text form-control" formControlName="mes" (change)="cambiar()">
           <option *ngFor="let mes of meses" [ngValue]="mes">
             {{ mes.nombre }}
           </option>
         </select>
       </li>
       <li class="nav-item  d-none d-lg-flex">Año</li>
       <li class="nav-item  d-none d-lg-flex">
         <select class="input-group-text form-control" formControlName="anio" (change)="cambiar()">
           <option *ngFor="let anio of anios" [ngValue]="anio">
             {{ anio }}
           </option>
         </select>
       </li>-->





    </ul>

    <ul class="navbar-nav navbar-nav-right">


      <li class="nav-item dropdown d-none d-xl-inline-flex" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="UserDropdown" ngbDropdownToggle >
         
              <!-- SVG que reemplaza la imagen de perfil -->
              <svg width="19" height="21" viewBox="0 -2 20 29" fill="none" xmlns="http://www.w3.org/2000/svg" style="
              height: 40px;
              width: 40px;
              border-radius: 50%;
              background-color: white;
              margin-right: 0rem;
              justify-content: center;
              align-items: center;">
                <path d="M9.5 10.5C10.9397 10.5 12.3205 9.94688 13.3386 8.96231C14.3566 7.97775 14.9286 6.64239 14.9286 5.25C14.9286 3.85761 14.3566 2.52226 13.3386 1.53769C12.3205 0.553123 10.9397 0 9.5 0C8.06025 0 6.67947 0.553123 5.66142 1.53769C4.64337 2.52226 4.07143 3.85761 4.07143 5.25C4.07143 6.64239 4.64337 7.97775 5.66142 8.96231C6.67947 9.94688 8.06025 10.5 9.5 10.5ZM7.56183 12.4688C3.38438 12.4688 0 15.7418 0 19.7818C0 20.4545 0.564063 21 1.2596 21H17.7404C18.4359 21 19 20.4545 19 19.7818C19 15.7418 15.6156 12.4688 11.4382 12.4688H7.56183Z" fill="#CCCCCC"/>
              </svg>
           
        </a>        
      
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" ngbDropdownMenu aria-labelledby="UserDropdown">
          <div class="dropdown-header text-center">
            <!-- SVG que reemplaza la imagen de perfil -->
            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 10.5C10.9397 10.5 12.3205 9.94688 13.3386 8.96231C14.3566 7.97775 14.9286 6.64239 14.9286 5.25C14.9286 3.85761 14.3566 2.52226 13.3386 1.53769C12.3205 0.553123 10.9397 0 9.5 0C8.06025 0 6.67947 0.553123 5.66142 1.53769C4.64337 2.52226 4.07143 3.85761 4.07143 5.25C4.07143 6.64239 4.64337 7.97775 5.66142 8.96231C6.67947 9.94688 8.06025 10.5 9.5 10.5ZM7.56183 12.4688C3.38438 12.4688 0 15.7418 0 19.7818C0 20.4545 0.564063 21 1.2596 21H17.7404C18.4359 21 19 20.4545 19 19.7818C19 15.7418 15.6156 12.4688 11.4382 12.4688H7.56183Z" fill="#CCCCCC"/>
            </svg>
            <p class="mb-1 mt-3 font-weight-semibold">{{ username }}</p>
            <p class="font-weight-light text-muted mb-0">{{ correo }}</p>
        </div>
        
          <a class="dropdown-item">Perfil <span class="badge badge-pill badge-danger">1</span><i
                  class="dropdown-item-icon ti-dashboard"></i></a>
          <a *ngIf="false" class="dropdown-item" (click)="clientes(ventanaCliente)">Clientes</a>
          <a class="dropdown-item">Notificaciones<i class="dropdown-item-icon ti-comment-alt"></i></a>
          <a class="dropdown-item">Actividad<i class="dropdown-item-icon ti-location-arrow"></i></a>
          <a class="dropdown-item">¿Preguntas?<i class="dropdown-item-icon ti-help-alt"></i></a>
          <a class="dropdown-item" (click)="salir()">
            Cerrar Sesión<i class="dropdown-item-icon ti-power-off"></i></a>
        </div>
      </li>
    </ul>

  </div>
</nav>

<ng-template #ventanaCliente let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-center">Cambio de cliente</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="cambioCliente()" #clienteForm="ngForm" class="forms-sample">
    <div class="modal-body">
      <p>Si desea cambiar a otro cliente solo seleccionelo y de "Aceptar"</p>
      <div>
        <select class="form-control" id="idempresa" name="clie" [(ngModel)]="clie.id">
          <option *ngFor="let client of clients" value="{{ client.id }}">
            {{ client.id }}
          </option>
        </select>
      </div>
    </div>
    <div class="modal-footer btn-group-sm">
      <button type="submit" class="btn btn-success">Aceptar</button>
      <button type="button" class="btn btn-danger" (click)="close()">
        Cancelar
      </button>
    </div>
  </form>
</ng-template>
