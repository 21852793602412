


    <div class="input-group">

        <input type="text"
               class="form-control "
               [bsConfig]="bsConfig"
               #dp="bsDatepicker"
               bsDatepicker [(bsValue)]="bsValue"
               (bsValueChange)="cambioFecha()"  >
               <span class="input-group-text" (click)="dp.toggle()">
                <i class="mdi mdi-calendar"></i>
            </span>
    </div>
