
<div *ngIf="barChartData.length===0; else divGrafica" class="alert alert-info">
Cargando datos ...
</div>
<ng-template #divGrafica>
  <div ><canvas style="height: 200px; width: 200px;"  baseChart
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="false"
    [chartType]="barChartType">
  </canvas>

</div>
    <div *ngIf="showLegend && diagnostico">
        <div class="row bar-container">
            <div *ngFor="let data of barChartData; let i = index" class="legend-item">
                <div class="legend-color" [style.background-color]="data.backgroundColor"></div>
                <div class="legend-label">{{ labels[i] }}</div>
            </div>
        </div>
    </div>
    <div *ngIf="showLegend && !diagnostico">
      <div class="row bar-container">
          <div *ngFor="let data of barChartData; let i = index" class="legend-item">
              <div class="legend-color" [style.background-color]="data.backgroundColor"></div>
              <div class="legend-label">{{ barChartLabels[i] }}</div>
          </div>
      </div>
  </div>




</ng-template>

