// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

   //Desarrollo
  /*urlAuth: 'http://localhost:8085/bunker/api/v1/auth/',
  urlUser: 'http://localhost:8085/bunker/api/v1/user/',
  url: 'http://localhost:8085/bunker/api/v1/',*/

   //QA
  /*urlAuth: 'https://azurianbunkerqa.azurian.com.mx/api/v1/auth/',
  urlUser: 'https://azurianbunkerqa.azurian.com.mx/api/v1/user/',
  url: 'https://azurianbunkerqa.azurian.com.mx/api/v1/',*/
  
  
   //PROD
  urlAuth: 'https://azurianbunker.azurian.com.mx/api/v1/auth/',
  urlUser: 'https://azurianbunker.azurian.com.mx/api/v1/user/',
  url: 'https://azurianbunker.azurian.com.mx/api/v1/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.









