import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Busqueda } from '../../../modules/auth/interfaces/beans.interfaces';
import { Conciliacion } from '../nomina/interfaces/nomina-details';
import { Observable } from 'rxjs';
import { ReporteInterfas } from './reporte.interface';
// import { Busqueda } from '../feature/auth/interfaces/beans.interface';




@Injectable({
    providedIn: 'root'
})
export class ReporteService {

    url = environment.url;

    constructor(private http: HttpClient) {}

    descargaReporte(documentos: Conciliacion[]): Observable<ReporteInterfas> {
        console.log('documento: ', documentos);
      return this.http.post<ReporteInterfas> (`${ this.url }reporte` , documentos); }
}
