import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Data, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {TokenStorageService} from '../services/auth/token-storage.service';
import {RolMenuResponse} from '../services/roles/interfaces/rol-response';
import {HttpClient} from '@angular/common/http';
import {RolService} from '../services/roles/rol.service';
import {RolModel} from '../../modules/auth/interfaces/beans.interfaces';

@Injectable({
  providedIn: 'root'
})
export class RolGuardServiceGuard implements CanActivate {

  constructor(private storageService: TokenStorageService, public router: Router , private rolService: RolService) {

  }
  canActivate( route: ActivatedRouteSnapshot): Observable<boolean > {
    console.log('Rol guard', route.data.expectedRol);
    const idMenu = route.data?.['idMenu'];
     const  rolesEsperado : Data  = [];
    this.rolService.getRolesByMenu(idMenu).subscribe( data  => {
      console.log('Data', data);
       data.forEach( rolMenu => {
         rolesEsperado.push(rolMenu.nombre.toUpperCase());
       });
    });

    //Implemanta un delay para que se cargue la data
    return new Observable<boolean>( observer => {
      setTimeout(() => {
        observer.next(this.checkRoles(rolesEsperado));
        observer.complete();
      }, 5000);
    });

  }

  checkRoles(rolesEsperado: Data): boolean {
    const user = this.storageService.getUser();
    console.log('Rol esperado ', rolesEsperado , 'user', user.rol.nombre);
    return rolesEsperado.includes(user.rol.nombre);
  }
}
