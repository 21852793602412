
export class Credenciales {
    username: string;
    password: string;

    clear() {
        this.username = '';
        this.password = '';
    }

    cleanPass() {
        this.password = '';
    }
}

export interface TwofactorDetails {
    username?: string;
}

export interface Code {
    code?: string
}

export interface VerificationCode {
    username: string,
    code: string
}

export interface Usuario {
    username: string;
    nombre: string;
    email: string;
    estado: boolean;
    fechaCreacion: Date;
    ultimaSesion: Date;
    ifallidos: number;
    numConexiones: number;
    cambiarClave: boolean;
    codigoAcceso: number;
    password: string;
    password1: string;
    password2: string;
    password3: string;
    password4: string;
    password5: string;
    generateQR: string;
}

export interface Cliente {
    id: string;
    nombre: string;
    fechaCreacion: Date;
    estado: boolean;
}

export class AsignacionUsuarioModel {
    usuario: UsuarioClass;
    cliente: ClienteModel;
    rol: RolModel;
}

export class PassWords {
    usuario: string;
    pass1: string;
    pass2: string;
}

export class CodigoAcceso {
    username: string;
    codigoAcceso: string;
}

export class CodigoTwoFa {
    code: number;
    id: string;
}

export class ClienteModel {
    id: string;
    nombre: string;
    fechaCreacion: Date;
    estado: boolean;


    clear() {
        this.estado = false;
        this.fechaCreacion = new Date;
        this.id = '';
        this.nombre = '';
    }

}

export class Plantilla {

    id: string;
    subject: string;
    uso: string;
    archivo: string;

    clear() {
        this.id = '';
        this.subject = '';
        this.uso = '';
        this.archivo = '';
    }
}

export class Solicitud {
    numero: string;
    fechaInicial: Date;
    fechaFinal: Date;
    rfcReceptor: string;
    solicitud: string;
    tipoSolicitud: string;
    estadoSolicitudSat: string;
    numeroCfdis: string;
}

export class Propiedades {
    id: string;
    valor: string;
    tipo: string;

    clear() {
        this.id = '';
        this.valor = '';
        this.tipo = '';
    }
}

export class UsuarioClass {
    username: string;
    nombre: string;
    email: string;
    estado: boolean;
    fechaCreacion: Date;
    ultimaSesion: Date;
    ifallidos: number;
    numConexiones: number;
    cambiarClave: boolean;
    codigoAcceso: number;
    password: string;
    password1: string;
    password2: string;
    password3: string;
    password4: string;
    password5: string;
    generateQR: boolean;


}
export class UsuarioNew {
    nombre: string;
    username: string;
    email: string;
    estado: boolean;
    password: string;
}


export class PermisosAgregar {
    rol: RolModel;
    permiso: Permisos;

    clear() {

    }
}

export class Roles {
    nombre: string;
    descripcion: string;
    estado: number;
    clear() {
        this.nombre = '';
        this.descripcion = '';
        this.estado = 0; }
}

export class Permisos {
    id: number;
    nombre: string;
    seleccion: boolean;
    descripcion: string;
    menu: Menus;
}

export class Menus {
    id: number;
    idPadre: number;
    nombre: string;
    descripcion: boolean;
}

export class RolModel {
    id: string;
    nombre: string;
    descripcion: string;
    estado: boolean;
    fechaCreacion: Date;
    cliente: ClienteModel;

    clear() {
        this.nombre = '';
        this.descripcion = '';
        this.estado = false; }
}

export class MenusVista {
    id: number;
    idPadre: number;
    nombre: string;
    seleccion: boolean;
}

export class PermisosVista {
    id: number;
    idPadre: number;
    nombre: string;
    seleccion: boolean;
    menu: Menus;
    descripcion: string;
}

export class EmpresaModel {
    rfc: string;
    razonSocial: string;
    fechaCreacion: Date;
    fechaBusqueda: Date;
    certificadoCsd: string;
    llavePrivadaCsd: string;
    passwordCsd: string;
    fechaInicioCsd: Date;
    fechaTerminoCsd: Date;
    certificadoFiel: string;
    llavePrivadaFiel: string;
    passwordFiel: string;
    fechaInicioFiel: Date;
    fechaTerminoFiel: Date;
    cliente: ClienteModel;
    propiedades: PropiedadEmpresa[];
}

export class PropiedadEmpresa {
    rfc: string;
    propiedad: string;
    valor: string;
}

export class Certificado {
    certificado: string;
    llavePrivada: string;
    password: string;
    fechaInicio: Date;
    fechaTermino: Date;
    razonSocial: string;
    rfc: string;
}

export interface EmpresaNueva {
    rfc: string;
    razonSocial: string;
    certificadoCsd: string;
    llavePrivadaCsd: string;
    passwordCsd: string;
    certificadoFiel: string;
    llavePrivadaFiel: string;
    passwordFiel: string;
}

export class EmpresaNuevaResponse {

    id:            number;
    rfc:           string;
    razonSocial:   string;
    fechaCreacion: string;
}

export class Cliente {
    idCliente: string;
}

export class Datos {
    totalSat: number;
    totalErp: number;
    importeSat: number;
    importeErp: number;
    efos: number;
    docEfos: number;
    duplicados: number;
    importe: number;
    totalCanceladosSat: number;
    totalCanceladosErp: number;
    importeCanceladosSat: number;
    importeCanceladosErp: number;

    clear() {
        this.totalSat = 0;
        this.totalErp = 0;
        this.importeSat = 0;
        this.importeErp = 0;
        this.efos = 0;
        this.docEfos = 0;
        this.duplicados = 0;
        this.importe = 0;
        this.totalCanceladosSat = 0;
        this.totalCanceladosErp = 0;
        this.importeCanceladosSat = 0;
        this.importeCanceladosErp = 0;
    }
}

export class DatosNomina {
    totalSat: number;
    totalErp: number;
    importeSat: number;
    importeErp: number;
    totalCanceladosSat: number;
    totalCanceladosErp: number;
    importeCanceladosSat: number;
    importeCanceladosErp: number;
    isrSat: number;
    isrErp: number;
    seguroSat: number;
    seguroErp: number;
    sueldoSat: number;
    sueldoErp: number;
    bonoSat: number;
    bonoErp: number;

    clear() {
        this.totalSat = 0;
        this.totalErp = 0;
        this.importeSat = 0;
        this.importeErp = 0;
        this.totalCanceladosSat = 0;
        this.totalCanceladosErp = 0;
        this.importeCanceladosSat = 0;
        this.importeCanceladosErp = 0;
        this.isrSat = 0;
        this.isrErp = 0;
        this.seguroSat = 0;
        this.seguroErp = 0;
        this.sueldoSat = 0;
        this.sueldoErp = 0;
        this.bonoSat = 0;
        this.bonoErp = 0;
    }
}

    export class ClienteRolUsuario {
        cliente: Cliente;
        rol: RolModel;
        usuario: UsuarioClass;
    }

    export class Busqueda {
        rfcEmisor: string;
        rfcReceptor: string;
        fechaInicio: string;
        fechaTermino: string;
        tipoComprobante: string;
    }
