import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {  Label } from 'ng2-charts';
import { NominaDetailsService } from 'src/app/core/services/nomina/nomina-details.service';
@Component({
  selector: 'app-bar-chart-horizontal',
  templateUrl: './bar-chart-horizontal.component.html',
  styleUrls: ['./bar-chart-horizontal.component.scss']
})
export class BarChartHorizontalComponent implements OnInit {

  @Input() intervalo = 0;
  @Input() tipoDocumento: string = null;
  barChartOptions: ChartOptions = {
    responsive: true,


    scales: {
      xAxes: [
        {
          ticks: {

            min: 0,
            stepSize: this.intervalo,
            fontColor: '#878e94',
            beginAtZero: true,

          },
          gridLines: {
            color: '#b9c0d3',
            zeroLineColor: '#b9c0d3',
            display: true,
            drawBorder: false
          }
        },
      ],
      yAxes: [
        {},
      ],
    },
  };
  @Input() barChartLabels: Label[] = [
  ];
  barChartType: ChartType = 'horizontalBar';

  barChartLegend = true;
  barChartPlugins = [];

  @Input() barChartData: ChartDataSets[] = [

  ];

  constructor(private route: Router, nominaDetails: NominaDetailsService) { }
  ngOnInit(): void {

  }
}
