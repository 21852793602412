import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ClienteService } from 'src/app/core/services/cliente/cliente.service';
import {Empresa} from "../../../core/services/cliente/cliente.interface";
import { TokenStorageService} from "../../../core/services/auth/token-storage.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {SelectEmpresaComponent} from '../../../modules/auth/components/select-cliente/select-empresa.component';
import {UsuarioService} from '../../../core/services/usuario/usuario.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: "app-layout-home",
  templateUrl: "./layout-home.component.html",
  styleUrls: ["./layout-home.component.scss"],
})
export class LayoutHomeComponent implements OnInit {
  title = "Azurian Bunker";
  empresas: Empresa[]=[];
  //tipoNaVar: string="empresa";
  idEmpresa: number;
  constructor(private clienteService: ClienteService, private route: ActivatedRoute, private storageService: TokenStorageService,
              private modalService: NgbModal, private router: Router, private usuarioService: UsuarioService, public activeModal: NgbModal) {

      const empresaSeleccionada = localStorage.getItem('empresaSeleccionada');
      console.log('empresaSeleccionada', empresaSeleccionada);
      if (  storageService.getUser().session.firstAccess === false && empresaSeleccionada === 'false'){
          console.log('Si !rfc && firstAccess is  ', localStorage.getItem("firstAccess"));
        this.asignarCliente();
      }


  }

  ngOnInit(): void {
      console.log('Layout home');
      console.log('Empresas -->', this.storageService.getUser().assginedEmpresas);
      this.empresas = this.storageService.getUser().assginedEmpresas;



  }


  withOutMenus(){
      let bandera= false;
      //console.log('localStorage.getItem(idEmpresa)', localStorage.getItem('idEmpresa'));
        //console.log('localStorage.getItem(tokenByEmpresa)', localStorage.getItem('tokenByEmpresa'));
      if( localStorage.getItem('tokenByEmpresa') !== null) {
         // console.log('localStorage.getItem(tokenByEmpresa)', localStorage.getItem('tokenByEmpresa'));
          bandera = true;
      }
      //console.log('bandera', bandera);
     return bandera;
  }


    asignarCliente() {
      console.log("Asignar cliente");
      const modalRef = this.modalService.open(SelectEmpresaComponent, {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static',
        keyboard: false
      });

      modalRef.result.then(
        (result) => {
            console.log('result is', result);
            localStorage.setItem('empresaSeleccionada', result.seleccionado);
         // this.empresaSeleccionada = true;
             location.href = location.href + '/diagnostico-fiscal/e/emitidas';
            location.reload();
          console.log("Modal cerrado con resultado:", result);
        },
        (reason) => {
            console.log("Modal descartado", reason);
            //this.router.navigate(['home/diagnostico-fiscal']);

        }
      );
  }
}
