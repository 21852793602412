import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {RolMenuResponse, RolResponse} from './interfaces/rol-response';
import { RolRequest } from './interfaces/rol-request';
import { RolModel } from 'src/app/modules/auth/interfaces/beans.interfaces';
import {Menu} from '../menu/menu';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  private baseUrl = `${environment.url}roles`;
  //private apiUrl = 'roles';

  constructor(private $http: HttpClient) { }

  public create(rolEmpresa:RolRequest):Observable<RolResponse>{
    return this.$http.post<RolResponse>(`${this.baseUrl}`,rolEmpresa);
  }

  public findById(idRol:number):Observable<RolResponse>{
    return this.$http.get<RolResponse>(`${this.baseUrl}/by-id/${idRol}`);
  }

  public findAll(): Observable<RolResponse[]> {
    console.log("baseURl " + this.baseUrl);
    const requestObservable = this.$http.get<RolResponse[]>(`${this.baseUrl}`);
    requestObservable.subscribe(
      (response) => {
        // La petición fue exitosa y aquí manejas la respuesta
        console.log('La petición se ha enviado y se ha recibido una respuesta', response);
      },
      (error) => {
        // Hubo un error al enviar la petición
        console.error('Error al enviar la petición', error);
      }
    );
    return requestObservable;
  }
  

  public deleteById(idRol:number):Observable<RolResponse>{
    return this.$http.delete<RolResponse>(`${this.baseUrl}/by-id/${idRol}`);
  }

  public updateById(idRol:number,requestRol:any):Observable<RolResponse>{
    return this.$http.put<RolResponse>(`${this.baseUrl}/by-id/${idRol}`,requestRol);
  }

  public deletePermisosById(idPermiso:number,requestRol:RolRequest):Observable<RolResponse>{
    return this.$http.put<RolResponse>(`${this.baseUrl}/remove/by-id/${idPermiso}/permisos`,requestRol);
  }

  public addPermisoById(idPermiso:number,requestRol:RolRequest):Observable<RolResponse>{
    return this.$http.put<RolResponse>(`${this.baseUrl}/add/by-id/${idPermiso}/permisos`,requestRol);
  }
  public getMenusByRol(idRol: number): Observable<Menu[]> {
    return this.$http.get<Menu[]>(`${this.baseUrl}/by-id/${idRol}/menus`);
  }

    public getRolesByMenu(idMenu: number): Observable<RolMenuResponse[]> {
        return this.$http.get<RolMenuResponse[]>(`${this.baseUrl}/by-id-menu/${idMenu}/roles`);
    }



}
