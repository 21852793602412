import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Empleado, ResponseEmpleado } from '../nomina/interfaces/nomina-empleado';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoService {

    url = environment.url;
    constructor(private http: HttpClient) { }
  
  
    getEmpleado(
     nombre:string
    ) {
      console.log("nombre del parametro hacia le back--->", nombre);
        let params= new HttpParams().set('nombre', nombre);
      let urlEmpleado = `${this.url}empleado/dashboard/nomina/empleado/by`;
      return this.http.get<Empleado[]>(urlEmpleado,{ params: params},);
    }
}
