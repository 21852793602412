<div class="table-responsive">
    <table class="table table-sm table-striped">
        <thead>
            <tr>
                <th colspan="7" class="text-center">
                    <h4 style="font-weight: bold;">DOCUMENTOS NO ENCONTRADOS
                        <i class="mdi mdi-file-document-box" style="color: #73b72b;" type="button"
                            ngbTooltip="Descarga de Reporte" (click)="descarga()"></i>
                    </h4>
                </th>
            </tr>
        </thead>
    </table>
</div>

<div class="ml-2">
    <label class="custom-label"> {{ this.totalRegistros}} Registros seleccionados</label>
</div>
<div class="row">
    <div class="col-12 col-lg-6 mx-auto">
        <div class="pagination-container">
            <div class="pagination-controls">
                <div class="btn-toolbar mb-1" role="toolbar">
                    <div class="btn-group-sm ml-1" role="group">
                        <button type="button" class="btn boton-verde-personalizado icono-blanco"
                            (click)="paginaInicial()" [disabled]="paginaActual === 1">
                            <b><span><i class="mdi mdi-skip-backward"></i></span></b>
                        </button>
                    </div>
                    <div class="btn-group-sm ml-1" role="group">
                        <button type="button" class="btn boton-verde-personalizado icono-blanco"
                            (click)="paginaAnterior()" [disabled]="paginaActual === 1">
                            <b><span><i class="mdi mdi-skip-previous"></i></span></b>
                        </button>
                    </div>
                    <div class="form-group ml-1">
                        <input type="number" class="form-control form-control-sm pagination-input"
                            [(ngModel)]="paginaActual" name="paginaActual" min="1" [max]="totalPaginas"
                            (change)="validarPagina()" style="width: 60px; text-align: center;">
                    </div>
                    <div class="btn-group-sm ml-1" role="group">
                        <button type="button" class="btn boton-verde-personalizado icono-blanco"
                            (click)="paginaSiguiente()" [disabled]="paginaActual === totalPaginas">
                            <b><span><i class="mdi mdi-skip-next"></i></span></b>
                        </button>
                    </div>
                    <div class="btn-group-sm ml-1" role="group">
                        <button type="button" class="btn boton-verde-personalizado icono-blanco" (click)="paginaFinal()"
                            [disabled]="paginaActual === totalPaginas">
                            <b><span><i class="mdi mdi-skip-forward"></i></span></b>
                        </button>
                    </div>
                </div>


            </div>


        </div>





    </div>


    <div class="col-12 col-lg-6 mx-auto">
        <div class="container">
            <div class="search-wrapper" onmouseover="showInput()" onmouseout="hideInput()">
                <!-- El input se mostrará al pasar el cursor sobre el contenedor .search-wrapper -->
                <input type="text" id="searchInput" class="input-search" placeholder="Buscar documentos"
                    onfocus="keepInputVisible()" onblur="hideInput()" [(ngModel)]="searchTerm" (input)="filterDocs()">
                <!-- El botón incluye el icono de la lupa -->
                <button type="button" class="btn btn-search">
                    <img src="assets/images/file-icons/64/search.png" alt="buscar">
                </button>
            </div>
        </div>
    </div>
</div>
<div class="form-group">
    <label class="custom-label"> Registros por página:</label>
    <select class="custom-select" [(ngModel)]="registrosPorPagina" name="registrosPorPagina"
            (change)="onPageSizeChange($event.target.value)" >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
    </select>
</div>
<div class="table-responsive">
    <table class="table table-sm table-striped">
        <thead>
            <tr *ngIf="contador()" class="text-right head-title">
                <th colspan="7">
                    <h4>Conciliar la cantidad de {{documentosSeleccionados.length}} Documentos
                        <i class="mdi mdi-alert text-danger" type="button" ngbTooltip="Conciliar Todos"
                            (click)="docsValid()"></i>
                    </h4>
                </th>
            </tr>
            <tr class="text-center head-column">
                <th scope="row">
                    <input type="checkbox" value="" id="flexCheckDefault" (change)="selectAll($event)" />
                </th>
                <th scope="col" style="color: white;">UUID</th>
                <th scope="col" style="color: white;">CANAL</th>
                <th scope="col" style="color: white;">ESTADO</th>
                <th scope="col" style="color: white;">RFC</th>
                <th scope="col" style="color: white;">RECEPTOR</th>
                <th scope="col" style="color: white;">TOTAL</th>
                <th scope="col" style="color: white;">OPCIONES</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let documento of docsFiltered">
                <th scope="row">
                    <div class="form-check">
                        <input type="checkbox" id="flexCheckDefault" [(ngModel)]="documento.checked"
                            (change)="seleccionado($event,documento.uuid)" />
                    </div>
                </th>
                <th class="text-center">{{documento.uuid}}</th>
                <td class="text-center">{{documento.canal}}</td>
                <td class="text-center">{{documento.estado | uppercase}}</td>
                <td class="text-center">{{documento.rfcEmisor}}</td>
                <td class="text-center">{{documento.nombreReceptor}}</td>
                <td class="text-center">{{documento.comprobante?.total | currency }} </td>
                <td class="text-center">
                    <i class="mdi mdi-check-outline" style="color: gray;" type="button"
                        ngbTooltip="Cargar el documento faltante" (click)="conciliacion(documento)"></i>
                    <i class="mdi mdi-account-search" style="color: gray;" type="button" ngbTooltip="Detalles"
                        (click)="visualizar(documento.uuid)"></i>
                </td>
            </tr>
        </tbody>
    </table>
</div>
