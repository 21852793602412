import { Injectable } from '@angular/core';
import { DataCliente } from "../cliente/cliente.interface";

const ANIO: string = 'anio';
const MES: string = 'mes';
const RFC: string = 'rfc';
const ID_EMPRESA: string = 'idEmpresa';
const FECHA_SELECCIONADA: string = 'fechaSeleccionada'; // Nuevo nombre para la clave de la fecha seleccionada en el localStorage

@Injectable({
  providedIn: 'root'
})
export class SearchStorageService {

  constructor() {
  }

  public getBusquedaMensual(): DataCliente {
    return {
      anio: Number(localStorage.getItem(ANIO)),
      mes: {
        id: Number(localStorage.getItem(MES)),
        nombre: ''
      },
      empresa: localStorage.getItem(RFC),
      idEmpresa: Number(localStorage.getItem(ID_EMPRESA)),
    };
  }

  public setFechaSeleccionada(fecha: Date) {
    localStorage.setItem(FECHA_SELECCIONADA, JSON.stringify(fecha));
  }

  public getFechaSeleccionada(): Date {
    const fechaGuardada = localStorage.getItem(FECHA_SELECCIONADA);
    console.log('fechaGuardada', fechaGuardada);
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1); // Restar un mes de la fecha actual
  
    return fechaGuardada ? new Date(JSON.parse(fechaGuardada)) : currentDate;
  }

  public limpiarFechaSeleccionada() {
    localStorage.removeItem(FECHA_SELECCIONADA);
  }
}
