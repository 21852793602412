
<div class="row">
  <div class="col-5 p-0 offset-1 m-auto">
    <div class="chart">
      <canvas baseChart
              [data]="pieChartData"
              [chartType]="pieChartType"
              [options]="pieChartOptions"
              [legend]="false"
              [plugins]="pieChartPlugins"
              [labels]="labelsPieAux"
              [colors]="chartColors"
      >
      </canvas>
    </div>

  </div>
  <div class="col-6 p-1 m-auto" *ngIf="!efos">
      <br>
      <div *ngFor="let label of labelsPie; let i = index" class="legend-item align-items-center">
        <div class="legend-color" [style.background-color]="chartColors[0].backgroundColor[i]"></div>
        <div class="legend-label ">{{ label }}</div>
      </div>
    </div>

  </div>











