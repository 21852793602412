import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {CodigoAcceso, CodigoTwoFa, Credenciales, Usuario} from '../../../modules/auth/interfaces/beans.interfaces';
import { Observable } from 'rxjs';

import { addToken  } from '../../interceptors/interceptor.service';
import {AccessToken} from './interface/login.interface';
import {SearchStorageService} from '../search/search-storage.service';
@Injectable({
  providedIn: 'root',
})
export class LoginService {

  url = environment.urlAuth;

  // private session: Session = null;
  public isPermit = false;
  constructor(private http: HttpClient, private searchStorageService: SearchStorageService) {}

  getLogin(login: Credenciales): Observable<AccessToken> {
    sessionStorage.setItem('password', login.password);
    sessionStorage.setItem('username', login.username);
    return this.http
      .post<AccessToken>(`${this.url}authenticate`, login);
  }
  
  /*validarCodigo(codigo: CodigoAcceso) {
    return this.http
      .post(`${this.url}authenticate/access-code`, codigo)
      .pipe(map((data) => data));
  }*/

  codigo_valido(codigoAcc: CodigoTwoFa) {
    return this.http
        .post(`${this.url}authorization/validate-code`, codigoAcc);
  }

  getSessionActiva() {}

  cerrarSession() {
    sessionStorage.clear()
    localStorage.clear();
    this.searchStorageService.limpiarFechaSeleccionada();
  }

  /*accesoConNipExitoso(acceso: boolean): void {
   this.isPermit = acceso;
    if (this.isPermit) {
      localStorage.setItem('isPermitido', 'OK');
    }
  }*/

  isPermitido() {
    console.log('local storage', localStorage.getItem('isPermitido'));
    return localStorage.getItem('isPermitido') === 'OK' ? true : false;
  }
}
