import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataCliente ,Mes} from './cliente.interface';

@Injectable({
  providedIn: "root",
})
export class ClienteObserverService{
 
  private _dataCliente$=new BehaviorSubject<DataCliente>({} as DataCliente);

  clienteOb(dataCliente: DataCliente) {
    if(this._dataCliente$.observers===null){
      this._dataCliente$=new BehaviorSubject<DataCliente>({} as DataCliente); 
      console.log("value del observable", this._dataCliente$);
    }
    this._dataCliente$.next(dataCliente);
  }

  getClientes$(): Observable<DataCliente> {
    console.log('dataClient: ', this._dataCliente$);
    return this._dataCliente$.asObservable();
  }

  getCliente(): Observable<DataCliente> {
    return this._dataCliente$;
  }

  getUnsuscirbe() {
    return this._dataCliente$.unsubscribe();
  }

}
