import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteObserverService } from 'src/app/core/services/cliente/cliente-observer.service';
import { RequestDetails } from 'src/app/core/services/nomina/interfaces/beans.interfaces';
import { Conciliacion } from 'src/app/core/services/nomina/interfaces/nomina-details';
import { NominaDetailsService } from 'src/app/core/services/nomina/nomina-details.service';
import {SearchStorageService} from '../../../../core/services/search/search-storage.service';
import {error} from 'protractor';
import {forkJoin} from 'rxjs';
import {delay} from 'rxjs/operators';
import {Sentido} from '../../enum/Sentido';


@Component({
  selector: 'app-list-conciliacion-docs',
  templateUrl: './list-conciliacion-docs.component.html',
  styleUrls: ['./list-conciliacion-docs.component.scss']
})

export class ListConciliacionDocsComponent implements OnInit {
  datainexistente = false;
  datastatus = false;
  datamonto = false;
  documentosPac: Conciliacion[] = [];
  docsStatus: Conciliacion[] = [];
  documentosMontos: Conciliacion[] = [];
  item: number = 0;
  estado: string = null;
  comparativa: string = null;
  titulo: string = null;
  offSetClick: number = 15;
  requetEmitido: any;
  tipoComprobante: string = null;
  page = 0;
  size = 10;
  sentido: string;
  constructor(private router: Router, private route: ActivatedRoute, private route2: Router,
    private nominaDetails: NominaDetailsService, private clienteObservador: ClienteObserverService,
              private searchData: SearchStorageService) {

  }
  ngOnDestroy(): void {
    console.log("ngOnDestroying...");
    this.clienteObservador.getUnsuscirbe();
  }

  ngOnInit(): void {
    this.comparativa = localStorage.getItem("comparativa");
    console.log('Comparativa', this.comparativa);
    this.estado = this.route.snapshot.paramMap.get("estado");
    console.log('Estado', this.estado);
   this.tipoComprobante = this.route.snapshot.paramMap.get('tipoComprobante');
    console.log('Tipo comprobante', this.comparativa);
    this.sentido = this.route.snapshot.paramMap.get('sentido');
    console.log('Sentido es:', this.sentido);
    this.clienteObservador.clienteOb(this.searchData.getBusquedaMensual());

    this.clienteObservador.getClientes$().subscribe(dataCliente => {
      this.requetEmitido = new RequestDetails();
      this.requetEmitido.rfc = dataCliente.empresa;
      this.requetEmitido.anio = dataCliente.anio;
      this.requetEmitido.mes = dataCliente.mes.id;

        this.loadAllData(this.requetEmitido, this.tipoComprobante);


    });

  }

  loadAllData(request: RequestDetails, tipoComprobante: string): void {
    const requestGetDocumento = this.nominaDetails.getConciliacionDocumentos(request, 'pac', tipoComprobante, this.page, this.size, this.sentido );
    const requestGetDocsStatus = this.nominaDetails.getActualizacionStatus(request, 'pac', tipoComprobante,0,10, this.sentido);

    forkJoin({requestGetDocumento, requestGetDocsStatus}).subscribe(response => {
      // tslint:disable-next-line:no-shadowed-variable
      const { requestGetDocumento, requestGetDocsStatus} = response;
      console.log('response: ', response);

      if (requestGetDocumento['error']) {
        console.log('getDocumentos_error: ', requestGetDocumento);
        this.documentosPac = [];
        this.datainexistente = false;

      } else {
        console.log('se obtuvo correctamente los documentos: ', requestGetDocumento);
        this.datainexistente = true;
        this.getDocumentos(requestGetDocumento);

      }

      if (requestGetDocsStatus['error']) {
        console.log('getDocsStatus_erorr: ', requestGetDocsStatus);
        this.docsStatus = [];
        this.datastatus = false;
      } else {
        this.datastatus = true;
        this.getDocsStatus(requestGetDocsStatus);
      }
      console.log("Finalizando  ===", this.datastatus, "datainexistente", this.datainexistente);
      if ( this.datastatus === false && this.datainexistente === false) {
        console.log("redireccion todos los datos son falsos");
        this.tipoRedirect(this.tipoComprobante);

      }

    });

  }
  
  visualizar(uuid: string): void {
    console.log("detalle", uuid);
    localStorage.setItem("uuid", uuid);
    this.router.navigate(["home/nomina-empresa-emitidas/dashboard-empresa/pac/vigente/detalle"]);
  }


  getDocumentos(data: Conciliacion[] | any): void {
    this.documentosPac = data;
    this.datainexistente = true;
  }



  getMontos(request: RequestDetails, tipoEmitida: string) {
    this.nominaDetails.getConciliacionMonto(request, tipoEmitida, 'pac').subscribe(data => {
      console.log('dataMonto', data.length);
      //this.existeConciliacion = true;
      this.documentosMontos = data;
      this.datamonto = true;
    });
  }

  getDocsStatus(data: Conciliacion[] | any): void {
    this.docsStatus = data;
    this.datastatus = true;
   // this.isConciliacionInexsistente = data.length > 0;
  }

  existDataChild(confirmacion: boolean) {
    this.datainexistente = confirmacion;
      console.log("existeConciliacion", this.datainexistente);
    if (this.datainexistente === false && this.datastatus === false) {
        this.tipoRedirect(this.tipoComprobante);
     }

  }


  existDataChildStatus(confirmacion: boolean) {
    console.log("confirmacion desde el componente estatus", confirmacion);
    this.datastatus = confirmacion;
    if (this.datainexistente === false && this.datastatus === false) {
      this.tipoRedirect(this.tipoComprobante);
    }

  }

  tipoRedirect(tipoComprobante: string) {

      console.log("redireccion");
      switch (tipoComprobante) {
        case 'factura':
          this.sentido === Sentido.EMITIDOS  ?  this.router.navigate(['/home/factura-empresa/dashboard/e/emitidas']) : this.router.navigate(['/home/factura-empresa/dashboard/r/recibidas']);
           break;
        case 'nomina':
          this.router.navigate(['/home/nomina-empresa-emitidas/dashboard-empresa']);
          break;
        case 'NotaCredito':
          this.sentido === Sentido.EMITIDOS ? this.router.navigate(['/home/nota-credito/dashboard/e/emitidas']) : this.router.navigate(['/home/nota-credito/dashboard/r/recibidas']);

          break;
        case 'pagos':
          this.sentido === Sentido.EMITIDOS ? this.router.navigate(['/home/pago/dashboard/e/emitidas']) : this.router.navigate(['/home/pago/dashboard/r/recibidas']);
          break;

      }
  }
}

