import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDetalle } from './interfaces/nomina-detalle';

@Injectable({
  providedIn: 'root'
})
export class NominaDetalleService {
  url = `${environment.url}details`;
  constructor(private http: HttpClient) {

  }
  getDetalleNomina(uuid: string): Observable<ResponseDetalle> {
    return this.http.get<ResponseDetalle>(`${this.url}/${uuid}`);
  }

}
