import { Injectable } from '@angular/core';
import {Usuario} from '../usuario/usuario.interfaces';
const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = 'auth-user';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    constructor() { }

    clearStorage(): void {
        localStorage.clear();
        window.sessionStorage.clear();

    }

    public saveToken(token: string): void {
        console.log("Guardando token ", token);
        console.log('cuando se guarda el token en el storage');
        window.sessionStorage.removeItem(TOKEN_KEY);
        console.log('limpiamos el tokenStorage y guardamos el nuevo token');
        window.sessionStorage.setItem(TOKEN_KEY, token);
        console.log('guardamos el token en el storage', sessionStorage.getItem(TOKEN_KEY));

    }

    public getToken(): string | null {
            console.log('getToken desde TokenStorageService', sessionStorage.getItem(TOKEN_KEY) );
        return window.sessionStorage.getItem(TOKEN_KEY);
    }

    public saveRefreshToken(token: string): void {
        window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
        window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
    }

    public getRefreshToken(): string | null {

        window.sessionStorage.removeItem(TOKEN_KEY);
        return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
    }

    public saveUser(user: Usuario): void {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    public getUser(): Usuario {
        const user = window.sessionStorage.getItem(USER_KEY);
        if (user !== null) {
            return JSON.parse(user);
        }
        return null;
    }

    /*public decodeToken(token: string): any {
        try {
            const  decodedToken = jwt_decode(token);
            console.log('Token decodificado:', decodedToken );
        } catch (error) {
            console.error('Error al decodificar el token:', error);
        }
    }*/

}
