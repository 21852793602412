<nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">

        <li class="nav-item" *ngFor="let menu of menus">
            <a class="nav-link" (click)="clickedMenu($event)" id="{{ menu.nombre}}" routerLink="{{menu.uri}}"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact :true}" #emailLink="routerLinkActive">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                        <path fill="#73b72b" d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zM272 192H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8
                        0-16-7.2-16-16s7.2-16 16-16zM256 304c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zM164 152v13.9c7.5 1.2 14.6 2.9 21.1 4.7c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5
                        14.2c-11-2.9-21.6-5-31.2-5.2c-7.9-.1-16 1.8-21.5 5c-4.8 2.8-6.2 5.6-6.2 9.3c0 1.8 .1 3.5 5.3 6.7c6.3 3.8 15.5 6.7 28.3 10.5l.7 .2c11.2 3.4 25.6 7.7 37.1 15c12.9 8.1 24.3 21.3 24.6 41.6c.3 20.9-10.5
                        36.1-24.8 45c-7.2 4.5-15.2 7.3-23.2 9V360c0 11-9 20-20 20s-20-9-20-20V345.4c-10.3-2.2-20-5.5-28.2-8.4l0 0 0 0c-2.1-.7-4.1-1.4-6.1-2.1c-10.5-3.5-16.1-14.8-12.6-25.3s14.8-16.1 25.3-12.6c2.5 .8 4.9 1.7
                        7.2 2.4c13.6 4.6 24 8.1 35.1 8.5c8.6 .3 16.5-1.6 21.4-4.7c4.1-2.5 6-5.5 5.9-10.5c0-2.9-.8-5-5.9-8.2c-6.3-4-15.4-6.9-28-10.7l-1.7-.5c-10.9-3.3-24.6-7.4-35.6-14c-12.7-7.7-24.6-20.5-24.7-40.7c-.1-21.1
                        11.8-35.7 25.8-43.9c6.9-4.1 14.5-6.8 22.2-8.5V152c0-11 9-20 20-20s20 9 20 20z"/></svg>
                  <strong> {{ menu.nombre }}</strong>
                </span>
                <i class="menu-arrow"></i>
            </a>
            <!-- recorrer la lista de submenus -->
            <div class="collapse" [ngbCollapse]="showSubMenu( menu.nombre )" >
                <ul class="nav flex-column sub-menu"  *ngFor="let submenu of menu.subMenus">
                    <li class="nav-item" >
                        <a class="nav-link" routerLink="{{ submenu.uri }}" routerLinkActive="active"
                           (click)="clickedSubMenu($event)" id="{{ submenu.nombre }}"
                           [routerLinkActiveOptions]="{exact :true}" #emailLink="routerLinkActive">{{ submenu.nombre}}</a>
                    </li>

                </ul>
            </div>
        </li>

        <!--<li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-factura"
                [ngClass]="{ active: emailLink.isActive}">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">
                        <path fill="#73b72b" d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2
                        0-24-10.8-24-24zM64 72c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8V72zm0 80v-16c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8zm144 263.9V440c0
                        4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-24.3c-11.3-.6-22.3-4.5-31.4-11.4-3.9-2.9-4.1-8.8-.6-12.1l11.8-11.2c2.8-2.6 6.9-2.8 10.1-.7 3.9 2.4 8.3 3.7 12.8 3.7h28.1c6.5 0
                        11.8-5.9 11.8-13.2 0-6-3.6-11.2-8.8-12.7l-45-13.5c-18.6-5.6-31.6-23.4-31.6-43.4 0-24.5 19.1-44.4 42.7-45.1V232c0-4.4 3.6-8 8-8h16c4.4 0 8 3.6 8 8v24.3c11.3 .6 22.3 4.5 31.4 11.4 3.9 2.9 4.1 8.8 .6
                        12.1l-11.8 11.2c-2.8 2.6-6.9 2.8-10.1 .7-3.9-2.4-8.3-3.7-12.8-3.7h-28.1c-6.5 0-11.8 5.9-11.8 13.2 0 6 3.6 11.2 8.8 12.7l45 13.5c18.6 5.6 31.6 23.4 31.6 43.4 0 24.5-19.1 44.4-42.7 45.1z"/></svg>
                 <strong>  Facturas </strong>
                </span>
                <i class="menu-arrow"></i>
            </a>
            <div class="collapse" [ngbCollapse]="parentId !== 'nav-factura'">
                <ul class="nav flex-column sub-menu">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home/factura-empresa/e/emitidas" routerLinkActive="active"
                            (click)="clickedSubMenu($event)" id="facturaEmpresaEmitida"
                            [routerLinkActiveOptions]="{exact :true}" #emailLink="routerLinkActive">Emitidas</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home/factura-empresa/r/recibidas" routerLinkActive="active"
                            (click)="clickedSubMenu($event)" id="facturaEmpresaRecibida"
                            [routerLinkActiveOptions]="{exact :true}" #emailLink="routerLinkActive">Recibidas</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home/factura/factura-pago" routerLinkActive="active"
                           (click)="clickedSubMenu($event)" id="facturaPago"
                           [routerLinkActiveOptions]="{exact :true}" #emailLink="routerLinkActive">Conciliacion avanzada</a>
                    </li>
                </ul>
            </div>
        </li>

        <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-nota-credito"
                [ngClass]="{ active: emailLink.isActive}">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                        <path fill="#73b72b" d="M326.7 403.7c-22.1 8-45.9 12.3-70.7 12.3s-48.7-4.4-70.7-12.3c-.3-.1-.5-.2-.8-.3c-30-11-56.8-28.7-78.6-51.4C70 314.6 48 263.9 48 208C48 93.1 141.1 0 256 0S464 93.1 464 208c0
                        55.9-22 106.6-57.9 144c-1 1-2 2.1-3 3.1c-21.4 21.4-47.4 38.1-76.3 48.6zM256 84c-11 0-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12
                        33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4
                        4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V312c0 11 9 20 20 20s20-9
                        20-20V298.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5
                        6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V104c0-11-9-20-20-20zM48 352H64c19.5 25.9 44 47.7 72.2 64H64v32H256
                        448V416H375.8c28.2-16.3 52.8-38.1 72.2-64h16c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V400c0-26.5 21.5-48 48-48z"/></svg>
                   <strong>Nota de crédito</strong>
                </span>
                <i class="menu-arrow"></i>
            </a>
            <div class="collapse" [ngbCollapse]="parentId !== 'nav-nota-credito'">
                <ul class="nav flex-column sub-menu">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home/factura/nota-credito/e/emitidas" routerLinkActive="active"
                            (click)="clickedSubMenu($event)" id="notaCreditoEmitida"
                            [routerLinkActiveOptions]="{exact :true}" #emailLink="routerLinkActive">Emitidas</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home/factura/nota-credito/r/recibidas"
                            routerLinkActive="active" (click)="clickedSubMenu($event)" id="notaCreditoRecibida"
                            [routerLinkActiveOptions]="{exact :true}" #emailLink="routerLinkActive">Recibidas</a>
                    </li>
                </ul>
            </div>
        </li>

        <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-pago" [ngClass]="{ active: emailLink.isActive}">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                        <path fill="#73b72b" d="M312 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7
                        2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7
                        24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2
                        2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3
                        10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7
                        0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z"/></svg>
                    <strong>Pagos</strong>
                </span>
                <i class="menu-arrow"></i>
            </a>

            <div class="collapse" [ngbCollapse]="parentId !== 'nav-pago'">
                <ul class="nav flex-column sub-menu">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home/pago/dashboard/e/emitida" routerLinkActive="active"
                            (click)="clickedSubMenu($event)" id="pagoEmitido" [routerLinkActiveOptions]="{exact :true}"
                            #emailLink="routerLinkActive">Emitida</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home/pago/dashboard/r/recibida" routerLinkActive="active"
                            (click)="clickedSubMenu($event)" id="pagoRecibido" [routerLinkActiveOptions]="{exact :true}"
                            #emailLink="routerLinkActive">Recibida</a>
                    </li>
                </ul>
            </div>

        </li>

        <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-link5" [ngClass]="{ active: emailLink.isActive}">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><
                        <path fill="#73b72b" d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 0v64h64V96H64zm384 0H192v64H448V96zM64 224v64h64V224H64zm384
                        0H192v64H448V224zM64 352v64h64V352H64zm384 0H192v64H448V352z"/></svg>
                   <strong>Nómina</strong>
                </span>
                <i class="menu-arrow"></i>
            </a>

            <div class="collapse" [ngbCollapse]="parentId !== 'nav-link5'">
                <ul class="nav flex-column sub-menu">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home/nomina-empresa-emitidas/dashboard-empresa"
                            routerLinkActive="active" (click)="clickedSubMenu($event)" id="nominaEmpresa"
                            [routerLinkActiveOptions]="{exact :true}" #emailLink="routerLinkActive">Emitida</a>
                    </li>
                </ul>
            </div>

        </li>

        <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-duplicados" [ngClass]="{ active: emailLink.isActive }"
               routerLink="/home/duplicados" routerLinkActive="active" #emailLink="routerLinkActive">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512">
                        <path fill="#73b72b" d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16
                        16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16
                        16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16
                        16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zM448 0c-17.7 0-32 14.3-32
                        32V512h64V192H624c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H480c0-17.7-14.3-32-32-32z"/></svg>
                  <strong>Duplicados</strong>
                </span>
                <i class="menu-arrow"></i>
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-efos" [ngClass]="{ active: emailLink.isActive }"
                routerLink="/home/efos" routerLinkActive="active" #emailLink="routerLinkActive">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512">
                        <path fill="#73b72b" d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16
                        16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16
                        16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16
                        16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zM448 0c-17.7 0-32 14.3-32
                        32V512h64V192H624c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H480c0-17.7-14.3-32-32-32z"/></svg>
                  <strong>EFOS</strong>
                </span>
                <i class="menu-arrow"></i>
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-reportes"
                [ngClass]="{ active: emailLink.isActive }" routerLink="/home/reportes/documento"
                routerLinkActive="active" #emailLink="routerLinkActive">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                        <path fill="#73b72b" d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3
                        0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 240a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-192c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16
                        16s16-7.2 16-16V288c0-8.8-7.2-16-16-16z"/></svg>
                  <strong> Reportes </strong>
                </span>
                <i class="menu-arrow"></i>
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-notificaciones"
               [ngClass]="{ active: emailLink.isActive }" routerLink="/home/notificaciones"
               routerLinkActive="active" #emailLink="routerLinkActive">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                        <path fill="#73b72b" d="M215.4 96H144 107.8 96v8.8V144v40.4 89L.2 202.5c1.6-18.1 10.9-34.9 25.7-45.8L48 140.3V96c0-26.5 21.5-48 48-48h76.6l49.9-36.9C232.2 3.9 243.9 0 256 0s23.8
                        3.9 33.5 11L339.4 48H416c26.5 0 48 21.5 48 48v44.3l22.1 16.4c14.8 10.9 24.1 27.7 25.7 45.8L416 273.4v-89V144 104.8 96H404.2 368 296.6 215.4zM0 448V242.1L217.6 403.3c11.1 8.2 24.6
                         12.7 38.4 12.7s27.3-4.4 38.4-12.7L512 242.1V448v0c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64v0zM176 160H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0
                          64H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
                  <strong> Notificaciones </strong>
                </span>
                <i class="menu-arrow"></i>
            </a>
        </li>


        <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-link6" [ngClass]="{ active: emailLink.isActive }">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512">
                        <path fill="#73b72b" d="M308.5 135.3c7.1-6.3 9.9-16.2 6.2-25c-2.3-5.3-4.8-10.5-7.6-15.5L304 89.4c-3-5-6.3-9.9-9.8-14.6c-5.7-7.6-15.7-10.1-24.7-7.1l-28.2 9.3c-10.7-8.8-23-16-36.2-20.9L199
                        27.1c-1.9-9.3-9.1-16.7-18.5-17.8C173.9 8.4 167.2 8 160.4 8h-.7c-6.8 0-13.5 .4-20.1 1.2c-9.4 1.1-16.6 8.6-18.5 17.8L115 56.1c-13.3 5-25.5 12.1-36.2 20.9L50.5 67.8c-9-3-19-.5-24.7 7.1c-3.5
                        4.7-6.8 9.6-9.9 14.6l-3 5.3c-2.8 5-5.3 10.2-7.6 15.6c-3.7 8.7-.9 18.6 6.2 25l22.2 19.8C32.6 161.9 32 168.9 32 176s.6 14.1 1.7 20.9L11.5 216.7c-7.1 6.3-9.9 16.2-6.2 25c2.3 5.3 4.8 10.5 7.6
                        15.6l3 5.2c3 5.1 6.3 9.9 9.9 14.6c5.7 7.6 15.7 10.1 24.7 7.1l28.2-9.3c10.7 8.8 23 16 36.2 20.9l6.1 29.1c1.9 9.3 9.1 16.7 18.5 17.8c6.7 .8 13.5 1.2 20.4 1.2s13.7-.4 20.4-1.2c9.4-1.1 16.6-8.6
                        18.5-17.8l6.1-29.1c13.3-5 25.5-12.1 36.2-20.9l28.2 9.3c9 3 19 .5 24.7-7.1c3.5-4.7 6.8-9.5 9.8-14.6l3.1-5.4c2.8-5 5.3-10.2 7.6-15.5c3.7-8.7 .9-18.6-6.2-25l-22.2-19.8c1.1-6.8
                        1.7-13.8 1.7-20.9s-.6-14.1-1.7-20.9l22.2-19.8zM112 176a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM504.7 500.5c6.3 7.1 16.2 9.9 25 6.2c5.3-2.3 10.5-4.8 15.5-7.6l5.4-3.1c5-3 9.9-6.3 14.6-9.8c7.6-5.7 10.1-15.7
                        7.1-24.7l-9.3-28.2c8.8-10.7 16-23 20.9-36.2l29.1-6.1c9.3-1.9 16.7-9.1 17.8-18.5c.8-6.7 1.2-13.5 1.2-20.4s-.4-13.7-1.2-20.4c-1.1-9.4-8.6-16.6-17.8-18.5L583.9 307c-5-13.3-12.1-25.5-20.9-36.2l9.3-28.2c3-9
                        .5-19-7.1-24.7c-4.7-3.5-9.6-6.8-14.6-9.9l-5.3-3c-5-2.8-10.2-5.3-15.6-7.6c-8.7-3.7-18.6-.9-25 6.2l-19.8 22.2c-6.8-1.1-13.8-1.7-20.9-1.7s-14.1 .6-20.9 1.7l-19.8-22.2c-6.3-7.1-16.2-9.9-25-6.2c-5.3 2.3-10.5
                        4.8-15.6 7.6l-5.2 3c-5.1 3-9.9 6.3-14.6 9.9c-7.6 5.7-10.1 15.7-7.1 24.7l9.3 28.2c-8.8 10.7-16 23-20.9 36.2L315.1 313c-9.3 1.9-16.7 9.1-17.8 18.5c-.8 6.7-1.2 13.5-1.2 20.4s.4 13.7 1.2 20.4c1.1 9.4 8.6 16.6
                        17.8 18.5l29.1 6.1c5 13.3 12.1 25.5 20.9 36.2l-9.3 28.2c-3 9-.5 19 7.1 24.7c4.7 3.5 9.5 6.8 14.6 9.8l5.4 3.1c5 2.8 10.2 5.3 15.5 7.6c8.7 3.7 18.6 .9 25-6.2l19.8-22.2c6.8 1.1 13.8 1.7 20.9 1.7s14.1-.6
                        20.9-1.7l19.8 22.2zM464 304a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
                    <strong>Configuración </strong>
                </span>
                <i class="menu-arrow"></i>
            </a>
            <div class="collapse" [ngbCollapse]="parentId !== 'nav-link6'">
                <ul class="nav flex-column sub-menu">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/config/empresa" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact :true}" #emailLink="routerLinkActive">Empresas</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/config/plantilla" routerLinkActive="active"
                            #emailLink="routerLinkActive">Plantillas Correo</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/config/propiedades" routerLinkActive="active"
                            #emailLink="routerLinkActive">Propiedades Sistema</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/config/usuario" routerLinkActive="active"
                            #emailLink="routerLinkActive">Gestión Usuario</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/config/roles" routerLinkActive="active"
                            #emailLink="routerLinkActive">Gestión De Roles</a>
                    </li>
                </ul>
            </div>
        </li>


        <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event)" id="nav-monitor"
                [ngClass]="{ active: emailLink.isActive }">
                <i class="menu-icon typcn typcn-arrow-maximise"></i>
                <span class="menu-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">
                        <path fill="#73b72b" d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4
                        24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/></svg>
                    <strong> Descargas</strong>
                </span>
                <i class="menu-arrow"></i>
            </a>
            <div class="collapse" [ngbCollapse]="parentId !== 'nav-monitor'">
                <ul class="nav flex-column sub-menu">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/config/monitor" routerLinkActive="active"
                            #emailLink="routerLinkActive">Monitor de solicitudes</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/sat/descarga-sat" routerLinkActive="active"
                            #emailLink="routerLinkActive">Descarga SAT</a>
                    </li>
                </ul>
            </div>
        </li>-->

    </ul>
</nav>
